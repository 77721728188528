const initialState = () => ({
    rooms: {
        2: "Тренажерный зал",
        11: "Бассейн",
        12: "Зал 1",
        13: "Зал 2",
        20: "Площадка",
        21: "Кардиозал",
        25: "Зал 3",
        28: "Зал пилатес",
    },
});

const state = initialState();

const getters = {
    rooms: (state) => {
        return state.rooms;
    },
};

const mutations = {};

const actions = {};

export default {
    state,
    getters,
    mutations,
    actions,
};
