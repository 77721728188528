<template>
    <!-- <div class="flex pb-6">
        <div class="flex-1 border-b border-purple py-3">
            <p class="text-xs font-medium text-purple text-center">Занятия</p>
        </div>

        <div class="flex-1 border-b border-dark py-3">
            <p class="text-xs text-dark text-center">Мероприятия</p>
        </div>
    </div> -->

    <div class="px-6">
        <div class="bg-white rounded-xl p-4 mb-2">
            <div class="bg-background flex items-center rounded-xl py-2">
                <div class="flex-1 pl-5">
                    <p class="text-xl font-medium">Hatha Yoga-1</p>
                </div>

                <div class="flex-initial text-center pr-3">
                    <p class="text-lg font-medium">17:30</p>
                    <p class="text-sm text-dark">45 мин</p>
                </div>
            </div>

            <div class="flex items-center mt-3">
                <div class="flex-initial">
                    <img
                        width="31px"
                        src="@/assets/images/trainer/trainer.png"
                        alt=""
                        class="rounded-full object-none object-top h-[31px] mr-3"
                    />
                </div>

                <div class="flex-initial">
                    <p>Евгений Снеговский</p>
                </div>

                <div class="flex-initial px-2">
                    <p>|</p>
                </div>

                <div class="flex-initial">
                    <p>Зал 1</p>
                </div>
            </div>
        </div>

        <div class="bg-white rounded-xl p-4 mb-2">
            <div class="bg-background flex items-center rounded-xl py-2">
                <div class="flex-1 pl-5">
                    <p class="text-xl font-medium">Hatha Yoga-1</p>
                </div>

                <div class="flex-initial text-center pr-3">
                    <p class="text-lg font-medium">17:30</p>
                    <p class="text-sm text-dark">45 мин</p>
                </div>
            </div>

            <div class="flex items-center mt-3">
                <div class="flex-initial">
                    <img
                        width="31px"
                        src="@/assets/images/trainer/trainer.png"
                        alt=""
                        class="rounded-full object-none object-top h-[31px] mr-3"
                    />
                </div>

                <div class="flex-initial">
                    <p>Евгений Снеговский</p>
                </div>

                <div class="flex-initial px-2">
                    <p>|</p>
                </div>

                <div class="flex-initial">
                    <p>Зал 1</p>
                </div>
            </div>
        </div>

        <div class="bg-white rounded-xl p-4 mb-2">
            <div class="bg-background flex items-center rounded-xl py-2">
                <div class="flex-1 pl-5">
                    <p class="text-xl font-medium">Hatha Yoga-1</p>
                </div>

                <div class="flex-initial text-center pr-3">
                    <p class="text-lg font-medium">17:30</p>
                    <p class="text-sm text-dark">45 мин</p>
                </div>
            </div>

            <div class="flex items-center mt-3">
                <div class="flex-initial">
                    <img
                        width="31px"
                        src="@/assets/images/trainer/trainer.png"
                        alt=""
                        class="rounded-full object-none object-top h-[31px] mr-3"
                    />
                </div>

                <div class="flex-initial">
                    <p>Евгений Снеговский</p>
                </div>

                <div class="flex-initial px-2">
                    <p>|</p>
                </div>

                <div class="flex-initial">
                    <p>Зал 1</p>
                </div>
            </div>
        </div>

        <div class="bg-white rounded-xl p-4 mb-2">
            <div class="bg-background flex items-center rounded-xl py-2">
                <div class="flex-1 pl-5">
                    <p class="text-xl font-medium">Hatha Yoga-1</p>
                </div>

                <div class="flex-initial text-center pr-3">
                    <p class="text-lg font-medium">17:30</p>
                    <p class="text-sm text-dark">45 мин</p>
                </div>
            </div>

            <div class="flex items-center mt-3">
                <div class="flex-initial">
                    <img
                        width="31px"
                        src="@/assets/images/trainer/trainer.png"
                        alt=""
                        class="rounded-full object-none object-top h-[31px] mr-3"
                    />
                </div>

                <div class="flex-initial">
                    <p>Евгений Снеговский</p>
                </div>

                <div class="flex-initial px-2">
                    <p>|</p>
                </div>

                <div class="flex-initial">
                    <p>Зал 1</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const person_schedule = computed(() => {
    return store?.getters?.person_schedule;
});

console.log(person_schedule);
</script>
