<template>
    <router-view @go-back="$emit('goBack')" />

    <div class="px-6">
        <div class="flex rounded-2xl bg-white py-4 pl-6 my-5">
            <img
                class="flex-initial mr-4"
                src="@/assets/images/icons/search.svg"
                alt=""
            />
            <input
                class="flex-1 w-full text-xs text-dark"
                type="text"
                placeholder="Найдем любую услугу или тренера"
            />
        </div>

        <div class="grid gap-2 grid-cols-2">
            <div
                class="rounded-xl bg-white"
                v-for="(value, index) in team"
                :key="index"
            >
                <router-link :to="'/team/' + index">
                    <img
                        v-if="value.Media"
                        class="rounded-xl object-cover w-full team__img"
                        v-lazy="value.Media[0].Uri"
                        alt=""
                    />
                    <img
                        v-else
                        class="rounded-xl object-cover w-full pb-[100%] bg-dark"
                        src=""
                        alt=""
                    />

                    <div class="p-4">
                        <p class="text-lg font-medium text-center">
                            {{ value.FirstName }} {{ value.LastName }}
                        </p>
                        <p class="text-xs text-dark text-center">
                            {{ GetSpec(value.Description) }}
                        </p>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
//import { useRoute } from "vue-router";

//const route = useRoute();
const store = useStore();

store.dispatch("setTeamData");

const team = computed(() => {
    return store.getters.team;
});
const GetSpec = (description) => {
    let specArr = [
            "Инструктор групповых программ",
            "Тренер по плаванию",
            "Персональный тренер",
            "Тренер по йоге",
            "Инструктор групповых программ и персональный тренер",
            "Координатор групповых программ",
        ],
        spec;

    if (description == null) return "";

    specArr.forEach((val) => {
        if (description.indexOf(val)) spec = val;
    });

    return spec;
};
</script>
