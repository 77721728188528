<template>
    <form
        @submit.prevent="sendConfirmation"
        class="flex flex-col items-center justify-between h-screen pb-16 px-6"
    >
        <div class="flex-initial">
            <img src="@/assets/images/auth/logo-short.png" alt="" />

            <p
                class="text-white font-Bodoni72osc text-46 leading-54 uppercase pt-6"
            >
                Введите номер телефона
            </p>
            <p class="text-white text-xl pt-6 pb-8">
                Для входа в мобильное приложение
            </p>

            <input
                v-maska="'+7 (###) ###-##-##'"
                class="w-full bg-white rounded-2xl text-22 py-3 pl-8"
                type="tel"
                v-model="$validate.phone.$model"
                placeholder="+7 000 000-00-00"
                name="phone"
                @maska="onMaska"
                @blur="$validate.phone.$touch()"
            />

            <span v-if="$validate.phone.$error" class="text-white">
                Неправильно набран номер телефона
            </span>
        </div>

        <div class="flex-initial">
            <button
                class="w-full bg-white rounded-2xl text-22 text-center py-3 mb-3"
                type="submit"
                :disabled="$validate.$invalid"
            >
                Получить код
            </button>

            <p class="text-white text-xl text-center">
                Нажимая кнопку я соглашась на правила чего-то там
            </p>
        </div>
    </form>
</template>

<script>
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
    setup() {
        let store = useStore(),
            router = useRouter(),
            phone = ref(""),
            phoneUnmasked = ref("");

        const sendConfirmation = () => {
            store.dispatch("sendConfirmation", phoneUnmasked.value);
            router.push("/check");
        };
        const onMaska = (event) => {
            phoneUnmasked.value = event.detail.unmasked;
        };

        return {
            $validate: useVuelidate(),
            phone,
            phoneUnmasked,
            sendConfirmation,
            onMaska,
        };
    },
    validations() {
        return {
            phone: {
                required,
                minLength: minLength(18),
            },
        };
    },
};
</script>
