<template>
    <div
        class="fixed top-0 left-0 bg-background w-full h-screen overflow-auto px-6 z-[1999]"
    >
        <div class="flex items-center justify-between py-6">
            <div class="flex-none">
                <button class="block" @click="$emit('goBack')">
                    <img src="@/assets/images/icons/back.svg" alt="" />
                </button>
            </div>

            <div class="flex-initial">
                <span class="text-lg">Меню</span>
            </div>

            <div class="flex-initial w-[37px]"></div>
        </div>

        <div class="mb-7">
            <img
                v-if="team.Media"
                class="rounded-2xl object-cover w-full"
                :src="team.Media[0].Uri"
                alt=""
            />
        </div>

        <div class="flex">
            <div class="flex-1">
                <p class="text-2xl font-medium">
                    {{ team.FirstName }} {{ team.LastName }}
                </p>
            </div>

            <div class="flex-initial">
                <img src="@/assets/images/icons/hearth_favorite.svg" alt="" />
            </div>
        </div>

        <p v-html="team.Description"></p>

        <div class="w-full h-px bg-gray my-6"></div>

        <p>Услуги</p>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const store = useStore();
const route = useRoute();

const team = computed(() => {
    return store.getters.team[route.params.id];
});
</script>
