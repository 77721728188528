import Axios from "axios";

const initialState = () => ({
    news: [],
});

const state = initialState();

const getters = {
    news: (state) => {
        return state.news;
    },
};

const mutations = {
    SET_NEWS: (state, payload) => {
        state.news = payload;
    },
};

const actions = {
    setNewsData: async ({ commit, getters }) => {
        if (Object.keys(getters.news).length > 0) return;

        let news = [],
            response = await Axios.get("https://lasalute.ru/ajax-news");

        if (response.data != null) {
            response.data.forEach((element) => {
                news.push(element);
            });

            commit("SET_NEWS", news);
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
