<template>
    <menu-burger
        v-if="route.meta.menu"
        :menu="menu"
        :user-data="props.userData"
        @toggle="toggleMenu"
    />
    <main-notification
        v-if="route.meta.notification"
        :notification="notification"
        @toggle="toggleNotification"
    />
    <div
        v-if="!route.meta.headerHide"
        class="flex items-center justify-between p-6"
    >
        <div class="flex-none">
            <button class="block" v-if="route.meta.menu" @click="toggleMenu">
                <img src="@/assets/images/icons/burger.svg" />
            </button>

            <button class="block" v-else @click="$emit('goBack')">
                <img src="@/assets/images/icons/back.svg" alt="" />
            </button>
        </div>

        <div class="flex-initial">
            <span class="text-lg">
                {{ $route.meta.title }}
            </span>
        </div>

        <div class="flex-none">
            <button
                v-if="route.meta.notification"
                @click="toggleNotification"
                class="block"
            >
                <img src="@/assets/images/icons/bell.svg" alt="" />
            </button>
        </div>
    </div>
</template>

<script setup>
import MenuBurger from "../templates/MenuBurger.vue";
import MainNotification from "../templates/MainNotification.vue";
import { ref, defineProps } from "vue";
import { useRoute } from "vue-router";

const props = defineProps(["userData"]);
const route = useRoute();

let menu = ref(false),
    notification = ref(false);

const toggleMenu = () => {
        menu.value = !menu.value;
    },
    toggleNotification = () => {
        notification.value = !notification.value;
    };
</script>
