<template>
    <form
        @submit.prevent="personRegister"
        class="flex flex-col items-center justify-between h-screen pb-16 px-6"
    >
        <div class="flex-initial">
            <img src="@/assets/images/auth/logo-short.png" alt="" />

            <p
                class="text-white font-Bodoni72osc text-46 leading-54 uppercase pt-6"
            >
                Введите оставшиеся данные
            </p>

            <input
                class="w-full bg-white rounded-2xl text-22 py-3 pl-8"
                type="text"
                v-model="$validate.login.$model"
                placeholder="Логин"
                name="login"
                @blur="$validate.login.$touch()"
            />

            <span v-if="$validate.login.$error" class="text-white">
                Неправильно набран номер телефона
            </span>

            <input
                class="w-full bg-white rounded-2xl text-22 py-3 pl-8 mt-5"
                type="text"
                v-model="$validate.nickname.$model"
                placeholder="ФИО"
                name="nickname"
                @blur="$validate.nickname.$touch()"
            />

            <span v-if="$validate.nickname.$error" class="text-white">
                Неправильно набран номер телефона
            </span>

            <input
                class="w-full bg-white rounded-2xl text-22 py-3 pl-8 mt-5"
                type="email"
                v-model="$validate.email.$model"
                placeholder="Email"
                name="email"
                @blur="$validate.email.$touch()"
            />

            <span v-if="$validate.email.$error" class="text-white">
                Неправильно набран номер телефона
            </span>

            <input
                class="w-full bg-white rounded-2xl text-22 py-3 pl-8 mt-5"
                type="text"
                v-model="$validate.password.$model"
                placeholder="Пароль"
                name="password"
                @blur="$validate.password.$touch()"
            />

            <span v-if="$validate.password.$error" class="text-white">
                Неправильно набран номер телефона
            </span>

            <input
                class="w-full bg-white rounded-2xl text-22 py-3 pl-8 mt-5"
                type="text"
                v-model="$validate.password_confirn.$model"
                placeholder="Подтверждение пароля"
                name="password_confirn"
                @blur="$validate.password_confirn.$touch()"
            />

            <span v-if="$validate.password_confirn.$error" class="text-white">
                Неправильно набран номер телефона
            </span>
        </div>

        <div class="flex-initial">
            <button
                class="w-full bg-white rounded-2xl text-22 text-center py-3 mb-3"
                type="submit"
                :disabled="$validate.$invalid"
            >
                Зарегистрироваться
            </button>

            <p class="text-white text-xl text-center">
                Нажимая кнопку я соглашась на правила чего-то там
            </p>
        </div>
    </form>
</template>

<script>
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
    setup() {
        let store = useStore(),
            router = useRouter(),
            login = ref(""),
            nickname = ref(""),
            email = ref(""),
            password = ref(""),
            password_confirn = ref("");

        const personRegister = () => {
            store.dispatch("personRegister", {
                login: login.value,
                nickname: nickname.value,
                email: email.value,
                password: password.value,
            });
            router.push("/");
        };

        return {
            $validate: useVuelidate(),
            login,
            nickname,
            email,
            password,
            password_confirn,
            personRegister,
        };
    },
    validations() {
        return {
            login: {
                required,
            },
            nickname: {
                required,
            },
            email: {
                required,
            },
            password: {
                required,
            },
            password_confirn: {
                required,
            },
        };
    },
};
</script>
