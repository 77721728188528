<template>
    <div
        class="fixed top-0 left-0 bg-background w-full h-screen overflow-auto pb-[90px] z-[1999] px-6"
    >
        <div class="flex items-center justify-between py-6">
            <div class="flex-none">
                <button class="block" @click="$emit('goBack')">
                    <img src="@/assets/images/icons/back.svg" alt="" />
                </button>
            </div>

            <div class="flex-initial">
                <span class="text-lg">Новость</span>
            </div>

            <div class="flex-initial w-[37px]"></div>
        </div>

        <div class="">
            <img :src="'https://lasalute.ru/' + news_item.tvs.images" alt="" />

            <div class="my-8">
                <p class="text-2xl font-medium">{{ news_item.pagetitle }}</p>
                <p class="text-dark">Зал пилатеса</p>
            </div>

            <p v-html="news_item.tvs.pageContent"></p>
        </div>

        <div class="w-full h-px bg-gray my-5"></div>

        <div class="flex items-center px-6">
            <div class="flex-initial">
                <img
                    width="62px"
                    src="@/assets/images/trainer/trainer.png"
                    alt=""
                    class="rounded-full object-cover object-top h-[62px] mr-4"
                />
            </div>

            <div class="flex-initial">
                <p class="text-sm text-dark">Инструктор</p>
                <p class="text-lg font-medium">
                    {{ news_item.tvs.lectoryiSpeaker }}
                </p>
            </div>
        </div>

        <div class="w-full h-px bg-gray my-5"></div>

        <div class="flex items-center px-6">
            <div class="flex-initial">
                <img
                    width="62px"
                    src="@/assets/images/icons/rubls.svg"
                    alt=""
                    class="rounded-full object-cover object-top h-[62px] mr-4"
                />
            </div>

            <div class="flex-initial">
                <p class="text-sm text-dark">Стоимость</p>
                <p
                    v-if="news_item.tvs.newsPrice"
                    v-html="news_item.tvs.newsPrice + ' ₽'"
                    class="text-lg font-medium"
                ></p>
                <p v-else class="text-lg font-medium">Бесплатно</p>
            </div>
        </div>
    </div>

    <div
        class="fixed w-full left-0 bottom-0 bg-white rounded-tr-2xl rounded-tl-2xl p-6 z-[2999]"
    >
        <div class="flex justify-between">
            <div class="flex-initial">
                <p class="text-sm text-dark">
                    {{ news_item.tvs.lectoryiDate }}
                </p>
            </div>

            <div class="flex-initial">
                <p class="text-sm text-dark">
                    {{ news_item.tvs.lectoryiTime }}
                </p>
            </div>
        </div>

        <!-- <p class="text-lg font-medium mt-5 mb-3">6 свободных мест</p>

        <div class="w-full h-2 bg-background rounded-xl mb-8">
            <div class="w-[35%] h-2 bg-purple rounded-xl"></div>
        </div>

        <button class="w-full text-lg text-white bg-purple rounded-2xl py-3">
            Записаться
        </button> -->
    </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const store = useStore();
const route = useRoute();
const ID = route.params.id;
const news = computed(() => {
    return store?.getters?.news;
});

let news_item;

news.value.forEach((element, index) => {
    if (ID == element.alias) news_item = news.value[index];
});
</script>
