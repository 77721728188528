<template>
    <div class="flex pb-7">
        <div class="flex-1 border-b border-purple py-3">
            <p class="text-xs font-medium text-purple text-center">
                Абонементы
            </p>
        </div>

        <div class="flex-1 border-b border-dark py-3">
            <p class="text-xs text-dark text-center">Услуги</p>
        </div>
    </div>

    <div class="px-6">
        <div v-for="(value, index) in person_contract.data" :key="index">
            <div class="flex items-center rounded-t-2xl bg-pink px-6 py-3">
                <div class="flex-initial">
                    <img
                        class="pr-5"
                        src="@/assets/images/agreement/agreement_days.png"
                        alt=""
                    />
                </div>

                <div class="flex-1">
                    <p class="text-sm text-white">
                        Ваш договор №{{ value.CardNumber }}
                    </p>
                    <p class="text-sm font-medium text-white">
                        {{ value.Template }}
                    </p>
                    <p class="text-sm font-medium text-white">
                        {{ value.Status }}
                    </p>
                </div>

                <div class="flex-initial">
                    <img
                        class="-rotate-90"
                        src="@/assets/images/icons/arrow_white.svg"
                        alt=""
                    />
                </div>
            </div>

            <div class="rounded-b-2xl bg-white pt-3 px-5 pb-7 mb-5">
                <div class="flex justify-between items-center">
                    <div class="flex-initial text-center">
                        <p class="text-sm">Осталось дней</p>
                        <p class="text-lg font-medium">
                            {{ value.AvailableVisitsLeft }}
                        </p>
                    </div>

                    <div class="flex-initial text-center">
                        <p class="text-sm">Заморожено дней</p>
                        <p class="text-lg font-medium">
                            {{ value.SuspensionDaysTotal }}
                        </p>
                    </div>

                    <div class="flex-initial text-center">
                        <p class="text-sm">Осталось заморозки</p>
                        <p class="text-lg font-medium">
                            {{ value.CanSuspendDays }}
                        </p>
                    </div>
                </div>

                <div class="w-full h-px bg-gray my-3"></div>

                <div class="flex justify-between pb-2">
                    <div class="flex-initial">
                        <p>Дата активации</p>
                    </div>

                    <div class="flex-initial">
                        <p>
                            {{ format(new Date(value.DateBegin), "dd.MM.Y") }}
                        </p>
                    </div>
                </div>

                <div class="flex justify-between pb-2">
                    <div class="flex-initial">
                        <p>Дата окончания</p>
                    </div>

                    <div class="flex-initial">
                        <p>{{ format(new Date(value.DateEnd), "dd.MM.Y") }}</p>
                    </div>
                </div>

                <div class="flex justify-between">
                    <div class="flex-initial">
                        <p>Количество дней</p>
                    </div>

                    <div class="flex-initial">
                        <p>{{ value.AvailableVisitsLeft }}</p>
                    </div>
                </div>

                <div v-if="value.CanSuspend" class="flex justify-center mt-5">
                    <div class="flex-initial">
                        <button
                            class="rounded-xl bg-pink text-white px-14 py-2"
                        >
                            Заморозка
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { format } from "date-fns";

const store = useStore();

const person_contract = computed(() => {
    return store?.getters?.person_contract;
});

// const cardActiveDay = () => {
//     let begin = new Date(person_contract.value.data[0].DateBegin).getTime(),
//         end = new Date(person_contract.value.data[0].DateEnd).getTime();

//     return ((Number(end) - Number(begin)) / 1000 / 86400).toFixed();
// };
</script>
