<template>
    <div class="px-6">
        <swiper
            :modules="modules"
            :slides-per-view="'auto'"
            :space-between="10"
            :free-mode="true"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            class="mb-6 swiper__auto"
        >
            <swiper-slide>
                <img
                    class="w-[44px]"
                    src="@/assets/images/icons/filter.svg"
                    alt=""
                />
            </swiper-slide>

            <swiper-slide class="text-sm rounded-lg bg-white p-3">
                <p>Групповые программы</p>
            </swiper-slide>

            <swiper-slide class="text-sm rounded-lg bg-white p-3">
                <p>Водные программы</p>
            </swiper-slide>

            <swiper-slide class="text-sm rounded-lg bg-white p-3">
                <p>Детские программы</p>
            </swiper-slide>
        </swiper>

        <div class="grid grid-cols-2 gap-3">
            <div class="flex-1 rounded-xl bg-white relative">
                <img
                    width="100%"
                    src="@/assets/images/profile/favorite.png"
                    alt=""
                />

                <img
                    class="absolute top-3 right-3"
                    src="@/assets/images/icons/hearth_favorite.svg"
                    alt=""
                />

                <div class="px-5 py-6">
                    <p class="pb-6 text-center">5 персональных тренировкок</p>

                    <div class="flex items-center">
                        <div class="flex-1">
                            <p class="text-xl font-medium">5 600 ₽</p>
                        </div>

                        <div class="flex-initial">
                            <img
                                src="@/assets/images/icons/plus_add.svg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex-1 rounded-xl bg-white relative">
                <img
                    width="100%"
                    src="@/assets/images/profile/favorite.png"
                    alt=""
                />

                <img
                    class="absolute top-3 right-3"
                    src="@/assets/images/icons/hearth_favorite.svg"
                    alt=""
                />

                <div class="px-5 py-6">
                    <p class="pb-6 text-center">5 персональных тренировкок</p>

                    <div class="flex items-center">
                        <div class="flex-1">
                            <p class="text-xl font-medium">5 600 ₽</p>
                        </div>

                        <div class="flex-initial">
                            <img
                                src="@/assets/images/icons/plus_add.svg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex-1 rounded-xl bg-white relative">
                <img
                    width="100%"
                    src="@/assets/images/profile/favorite.png"
                    alt=""
                />

                <img
                    class="absolute top-3 right-3"
                    src="@/assets/images/icons/hearth_favorite.svg"
                    alt=""
                />

                <div class="px-5 py-6">
                    <p class="pb-6 text-center">5 персональных тренировкок</p>

                    <div class="flex items-center">
                        <div class="flex-1">
                            <p class="text-xl font-medium">5 600 ₽</p>
                        </div>

                        <div class="flex-initial">
                            <img
                                src="@/assets/images/icons/plus_add.svg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex-1 rounded-xl bg-white relative">
                <img
                    width="100%"
                    src="@/assets/images/profile/favorite.png"
                    alt=""
                />

                <img
                    class="absolute top-3 right-3"
                    src="@/assets/images/icons/hearth_favorite.svg"
                    alt=""
                />

                <div class="px-5 py-6">
                    <p class="pb-6 text-center">5 персональных тренировкок</p>

                    <div class="flex items-center">
                        <div class="flex-1">
                            <p class="text-xl font-medium">5 600 ₽</p>
                        </div>

                        <div class="flex-initial">
                            <img
                                src="@/assets/images/icons/plus_add.svg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { FreeMode } from "swiper/modules";
import "swiper/css";

const modules = [FreeMode];
</script>
