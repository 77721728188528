<template>
    <div class="px-6 container">
        <div class="flex weekday direction__nav" id="weekday">
            <div
                v-for="(value, index) in getWeekday"
                :key="index"
                class="flex-1 pr-2 direction__nav--item"
            >
                <input
                    type="radio"
                    class="hidden peer/weekday"
                    name="weekday"
                    :id="'weekday' + index"
                    @change="navActivate(index)"
                />

                <label
                    class="block rounded-3xl text-sm text-center bg-white border border-white p-2 peer-checked/weekday:bg-purple peer-checked/weekday:border-purple peer-checked/weekday:text-white"
                    :for="'weekday' + index"
                >
                    <p class="py-1.5">
                        {{ value.date }}
                    </p>
                    <p class="rounded-full py-1 bg-white text-black">
                        {{ value.week }}
                    </p>
                </label>
            </div>
        </div>

        <swiper
            :modules="modules"
            :slides-per-view="'auto'"
            :space-between="10"
            :free-mode="true"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            class="my-6 swiper__auto"
        >
            <swiper-slide @click="filterOpen">
                <img
                    class="w-[44px]"
                    src="@/assets/images/icons/filter.svg"
                    alt=""
                />
            </swiper-slide>

            <swiper-slide>
                <input
                    type="radio"
                    class="hidden peer/group"
                    name="filter-programme"
                    id="group"
                    @click="filterProgrammeGet"
                />

                <label
                    class="block text-sm rounded-lg bg-white p-3 peer-checked/group:bg-purple peer-checked/group:text-white"
                    for="group"
                >
                    <p>Групповые программы</p>
                </label>
            </swiper-slide>

            <swiper-slide>
                <input
                    type="radio"
                    class="hidden peer/water"
                    name="filter-programme"
                    id="water"
                    @click="filterProgrammeGet"
                />

                <label
                    class="block text-sm rounded-lg bg-white p-3 peer-checked/water:bg-purple peer-checked/water:text-white"
                    for="water"
                >
                    <p>Водные программы</p>
                </label>
            </swiper-slide>

            <!-- <swiper-slide class="text-sm rounded-lg bg-white p-3">
                <p>Детские программы</p>
            </swiper-slide> -->
        </swiper>

        <div
            v-show="filter_block"
            class="fixed top-0 left-0 w-full h-screen bg-white overflow-scroll px-6 z-[1999]"
        >
            <div class="flex justify-center pt-4">
                <div class="flex-initial w-16 h-2 bg-purple rounded"></div>
            </div>

            <button class="block" @click="filterOpen">
                <img src="@/assets/images/icons/close.svg" alt="" />
            </button>

            <div class="flex items-center mt-5">
                <div class="flex-1">
                    <p class="text-22 font-medium">Фильтр</p>
                </div>

                <div class="flex-initial">
                    <button class="text-sm text-dark" @click="filterReset">
                        Сбросить
                    </button>
                </div>
            </div>

            <form @submit.prevent="filterTime" class="my-5">
                <div class="flex rounded-2xl bg-background p-1.5">
                    <input
                        type="radio"
                        class="hidden peer/class"
                        name="filter"
                        id="class"
                        @change="filterChange"
                        checked
                    />

                    <input
                        type="radio"
                        class="hidden peer/team"
                        name="filter"
                        id="team"
                        @change="filterChange"
                    />

                    <label
                        class="flex-1 rounded-xl text-center py-4 peer-checked/class:bg-white peer-checked/class:font-medium"
                        for="class"
                    >
                        <p>Занятия</p>
                    </label>

                    <label
                        class="flex-1 rounded-xl text-center py-4 peer-checked/team:bg-white peer-checked/team:font-medium"
                        for="team"
                    >
                        <p>Тренеры</p>
                    </label>
                </div>

                <div class="flex my-6">
                    <input
                        type="radio"
                        class="hidden peer/time1"
                        name="filter_time"
                        id="time1"
                        value="07:15-12:00"
                        v-model="form.filter_time"
                    />

                    <input
                        type="radio"
                        class="hidden peer/time2"
                        name="filter_time"
                        id="time2"
                        value="12:00-17:00"
                        v-model="form.filter_time"
                    />

                    <input
                        type="radio"
                        class="hidden peer/time3"
                        name="filter_time"
                        id="time3"
                        value="17:00-21:00"
                        v-model="form.filter_time"
                    />

                    <label
                        for="time1"
                        class="flex-1 rounded-xl text-center bg-background py-5 mr-1.5 peer-checked/time1:bg-purple peer-checked/time1:text-white"
                    >
                        <p class="text-sm font-medium">Утро</p>
                        <p class="text-sm">7:15-12:00</p>
                    </label>

                    <label
                        for="time2"
                        class="flex-1 rounded-xl text-center bg-background py-5 mr-1.5 peer-checked/time2:bg-purple peer-checked/time2:text-white"
                    >
                        <p class="text-sm font-medium">День</p>
                        <p class="text-sm">12:00-17:00</p>
                    </label>

                    <label
                        for="time3"
                        class="flex-1 rounded-xl text-center bg-background py-5 peer-checked/time3:bg-purple peer-checked/time3:text-white"
                    >
                        <p class="text-sm font-medium">Вечер</p>
                        <p class="text-sm">17:00-21:00</p>
                    </label>
                </div>

                <div class="hidden bg-background rounded-2xl py-4 px-6 mb-8">
                    <div class="flex-initial">
                        <img
                            class="pr-5"
                            src="@/assets/images/icons/search.svg"
                            alt=""
                        />
                    </div>

                    <div class="flex-1">
                        <input
                            class="w-full text-sm text-dark bg-background border-none outline-none"
                            type="text"
                            name="search_filter"
                            placeholder="Поиск"
                        />
                    </div>
                </div>

                <div v-show="filter == 'class'">
                    <div v-for="(value, index) in lessons_name" :key="index">
                        <div class="flex items-center">
                            <label
                                :for="'lessons' + index"
                                class="flex-1 text-lg"
                            >
                                {{ value.name }}
                            </label>

                            <input
                                class="flex-initial"
                                type="checkbox"
                                name="filter_lessons"
                                :id="'lessons' + index"
                                :value="value.id"
                                v-model="form.filter_lessons"
                            />
                        </div>

                        <div class="w-full h-px my-4 bg-gray"></div>
                    </div>
                </div>

                <div v-show="filter == 'team'">
                    <div v-for="(value, index) in team" :key="index">
                        <div class="flex items-center">
                            <label :for="'team' + index" class="flex-1 text-lg">
                                {{ value.LastName }}
                                {{ value.FirstName }}
                            </label>

                            <input
                                class="flex-initial"
                                type="checkbox"
                                name="filter_team"
                                :id="'team' + index"
                                :value="value.ID"
                                v-model="form.filter_team"
                            />
                        </div>

                        <div class="w-full h-px my-4 bg-gray"></div>
                    </div>
                </div>

                <button
                    class="sticky bottom-6 w-full text-lg text-white bg-purple rounded-2xl py-3"
                    type="submit"
                >
                    Применить
                </button>
            </form>
        </div>

        <div
            v-if="
                Object.keys(schedule).length > 0 &&
                Object.keys(lessons).length > 0
            "
            class="list__nav"
        >
            <div
                v-for="(days, index) in schedule"
                :key="index"
                class="list__nav--item"
            >
                <div
                    class="flex schedule"
                    v-for="(data, time) in days"
                    :key="time"
                >
                    <div class="flex-initial pr-7 w-[75px]">
                        <p class="text-sm">{{ time }}</p>
                    </div>

                    <div class="flex-1">
                        <router-link
                            v-for="value in data"
                            :key="value.ID"
                            :to="
                                '/lessons/' +
                                value.ID +
                                '?date=' +
                                index +
                                '&time=' +
                                time
                            "
                            class="block text-white rounded-2xl p-6 pt-5 mb-3 schedule__item"
                            :class="getBackground()"
                            :data-lesson="value.LessonTypeID"
                            :data-master="value.MasterID"
                        >
                            <div class="flex mb-8">
                                <div class="flex-1">
                                    <p class="text-22 font-medium">
                                        {{
                                            replaceName(
                                                lessons[value.LessonTypeID].Name
                                            )
                                        }}
                                    </p>
                                    <p class="text-sm">
                                        {{ rooms[value.RoomID] }}
                                    </p>
                                </div>

                                <div class="flex-initial">
                                    <img
                                        src="@/assets/images/icons/arrow_round.svg"
                                        alt=""
                                    />
                                </div>
                            </div>

                            <div class="flex items-center mt-3">
                                <div class="flex-initial">
                                    <img
                                        width="31px"
                                        src="@/assets/images/trainer/trainer.png"
                                        alt=""
                                        class="rounded-full object-none object-top h-[31px] mr-3"
                                    />
                                </div>

                                <div
                                    class="flex-initial"
                                    v-if="Object.keys(team).length > 0"
                                >
                                    <p>
                                        {{ team[value.MasterID].LastName }}
                                        {{ team[value.MasterID].FirstName }}
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <content-loader
            v-else
            class="w-full h-[60vh] pl-[75px]"
            viewBox="0 0"
            speed="2"
            primary-color="#f3f3f3"
            secondary-color="#ecebeb"
        >
            <rect x="0" y="0" rx="12" width="290" height="160" />
            <rect x="0" y="170" rx="12" width="290" height="160" />
            <rect x="0" y="340" rx="12" width="290" height="160" />
        </content-loader>
    </div>
</template>

<script setup>
import { ref, reactive, toRefs, computed, onMounted } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { FreeMode } from "swiper/modules";
import { useStore } from "vuex";
import { format, getDate, addDays, isAfter, isBefore, isEqual } from "date-fns";
import { ru } from "date-fns/locale";
import {
    replaceName,
    navActivate,
    listChangeDisplay,
    elementChangeDisplay,
    timeChangeDisplay,
} from "@/functions/function";
import { ContentLoader } from "vue-content-loader";
import useVuelidate from "@vuelidate/core";
import "swiper/css";

const store = useStore();
const modules = [FreeMode];

let filter = ref("class"),
    filter_block = ref(false),
    filter_rogramme = ref(false),
    bg_count = 0;

store.dispatch("setTeamData");
store.dispatch("setLessonsData");
store.dispatch("setScheduleData");

const getWeekday = computed(() => {
    let weekday = [],
        date = new Date();

    for (let i = 0; i < 7; i++) {
        weekday.push({
            date: getDate(date),
            week: format(date, "EEEEEE", { locale: ru }),
        });

        date = addDays(date, 1);
    }

    return weekday;
});
const schedule = computed(() => {
    return store?.getters?.schedule;
});
const team = computed(() => {
    return store?.getters?.team;
});
const lessons = computed(() => {
    return store?.getters?.lessons;
});
const lessons_name = computed(() => {
    return store?.getters?.lessons_name;
});
const rooms = computed(() => {
    return store?.getters?.rooms;
});

const form = reactive({
    filter_time: "",
    filter_lessons: [],
    filter_team: [],
});
const rules = {
    form: {
        filter_time: {},
        filter_lessons: {},
        filter_team: {},
    },
};
useVuelidate(rules, form);

onMounted(() => {
    let weekday =
        document.getElementById("weekday").firstElementChild.firstElementChild;
    weekday.checked = true;
});

const filterChange = (event) => {
    filter.value = event.target.id;
};
const filterOpen = () => {
    filter_block.value = !filter_block.value;
};
const filterProgrammeGet = (event) => {
    let items = document.querySelectorAll(".schedule__item"),
        schedules = document.querySelectorAll(".schedule");

    listChangeDisplay(items, "none");
    listChangeDisplay(schedules, "flex");

    if (filter_rogramme.value == event.target.id) {
        event.target.checked = false;
        filter_rogramme.value = false;
    } else filter_rogramme.value = event.target.id;

    if (filter_rogramme.value) {
        let filter = document.querySelectorAll(`.${filter_rogramme.value}`);

        listChangeDisplay(filter, "block");
        timeChangeDisplay(schedules);
    } else listChangeDisplay(items, "block");
};
const filterTime = () => {
    filterOpen();

    const { filter_time, filter_lessons, filter_team } = toRefs(form);
    let schedules = document.querySelectorAll(".schedule"),
        schedules_items = document.querySelectorAll(".schedule__item"),
        date = "1970-01-01T";

    listChangeDisplay(schedules, "flex");
    listChangeDisplay(schedules_items, "block");

    if (filter_time.value != "") {
        const times = filter_time.value.split("-");

        let begin = new Date(date + times[0]),
            end = new Date(date + times[1]);

        schedules.forEach((schedule) => {
            let checked_time = new Date(
                date + schedule.children[0].children[0].innerHTML
            );

            if (
                (!isBefore(checked_time, begin) &&
                    !isAfter(checked_time, end)) ||
                isEqual(checked_time, begin) ||
                isEqual(checked_time, end)
            )
                console.log("true");
            else elementChangeDisplay(schedule, "none");
        });
    }

    if (filter_lessons.value.length) {
        schedules_items.forEach((item) => {
            let lesson_id = item.dataset.lesson;

            if (!filter_lessons.value.includes(lesson_id)) {
                elementChangeDisplay(item, "none");
            }
        });

        timeChangeDisplay(schedules);
    }

    if (filter_team.value.length) {
        schedules_items.forEach((item) => {
            let master_id = item.dataset.master;

            if (!filter_team.value.includes(master_id)) {
                elementChangeDisplay(item, "none");
            }
        });

        timeChangeDisplay(schedules);
    }
};
const filterReset = () => {
    let schedules = document.querySelectorAll(".schedule"),
        schedules_items = document.querySelectorAll(".schedule__item");

    form.filter_time = "";
    form.filter_lessons = [];
    form.filter_team = [];

    listChangeDisplay(schedules, "flex");
    listChangeDisplay(schedules_items, "block");
};

const getBackground = () => {
    let color = "";

    switch (bg_count) {
        case 0:
            color = "bg-purple";
            break;
        case 1:
            color = "bg-pink";
            break;
        case 2:
            color = "bg-indian";
            break;
        case 3:
            color = "bg-yellow";
            break;
    }

    if (bg_count == 3) bg_count = 0;
    else bg_count++;

    return color;
};
</script>
