<template>
    <form
        @submit.prevent="setPhoneCode"
        class="flex flex-col justify-between h-screen pb-32 px-6"
    >
        <div class="flex-initial">
            <img src="@/assets/images/auth/logo-short.png" alt="" />

            <p class="text-white font-Bodoni72osc text-46 leading-54 uppercase">
                sms код уже на пути к Вам
            </p>
            <p class="text-white text-xl pt-6 pb-8">
                Смс с кодом отправлен на номер {{ phone }} и действует 5 минут
            </p>
        </div>

        <div class="flex-initial">
            <p class="text-white text-lg">Введите смс код</p>

            <div class="flex flex-shrink flex-wrap my-4">
                <div class="flex-1">
                    <input
                        maxlength="1"
                        v-model="$validate.pass1.$model"
                        class="w-[50px] h-[50px] bg-white text-center rounded-xl text-22 mr-[10px]"
                        type="text"
                        @input="focusNext"
                    />
                </div>

                <div class="flex-1">
                    <input
                        maxlength="1"
                        v-model="$validate.pass2.$model"
                        class="w-[50px] h-[50px] bg-white text-center rounded-xl text-22 mr-[10px]"
                        type="text"
                        @input="focusNext"
                    />
                </div>

                <div class="flex-1">
                    <input
                        maxlength="1"
                        v-model="$validate.pass3.$model"
                        class="w-[50px] h-[50px] bg-white text-center rounded-xl text-22 mr-[10px]"
                        type="text"
                        @input="focusNext"
                    />
                </div>

                <div class="flex-1">
                    <input
                        maxlength="1"
                        v-model="$validate.pass4.$model"
                        class="w-[50px] h-[50px] bg-white text-center rounded-xl text-22 mr-[10px]"
                        type="text"
                        @input="focusNext"
                    />
                </div>

                <div class="flex-1">
                    <input
                        maxlength="1"
                        v-model="$validate.pass5.$model"
                        class="w-[50px] h-[50px] bg-white text-center rounded-xl text-22 mr-[10px]"
                        type="text"
                        @input="focusNext"
                    />
                </div>

                <div class="flex-initial">
                    <input
                        maxlength="1"
                        v-model="$validate.pass6.$model"
                        class="w-[50px] h-[50px] bg-white text-center rounded-xl text-22"
                        type="text"
                    />
                </div>
            </div>

            <p class="text-white text-lg font-medium text-center">
                Отправить код повторно
            </p>
        </div>

        <div class="flex-initial">
            <button
                class="w-full bg-white rounded-2xl text-22 text-center py-3 mb-3"
                type="submit"
                :disabled="$validate.$invalid"
            >
                Отправить
            </button>
        </div>
    </form>
</template>

<script>
import { ref, computed } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
    setup() {
        let store = useStore(),
            router = useRouter(),
            pass1 = ref(""),
            pass2 = ref(""),
            pass3 = ref(""),
            pass4 = ref(""),
            pass5 = ref(""),
            pass6 = ref(""),
            password = ref("");

        const setPhoneCode = () => {
            password.value =
                pass1.value.toString() +
                pass2.value.toString() +
                pass3.value.toString() +
                pass4.value.toString() +
                pass5.value.toString() +
                pass6.value.toString();

            console.log(password.value);
            store.dispatch("setPhoneCode", password.value);
            router.push("/registration");
        };
        const phone = computed(() => {
            return store.getters.phone;
        });

        const focusNext = (event) => {
            event.target.parentElement.nextSibling.children[0].focus();
        };
        const focusPrev = (event) => {
            event.target.parentElement.previousSibling.children[0].focus();
        };

        return {
            $validate: useVuelidate(),
            password,
            pass1,
            pass2,
            pass3,
            pass4,
            pass5,
            pass6,
            phone,
            setPhoneCode,
            focusNext,
            focusPrev,
        };
    },
    validations() {
        return {
            pass1: {
                required,
            },
            pass2: {
                required,
            },
            pass3: {
                required,
            },
            pass4: {
                required,
            },
            pass5: {
                required,
            },
            pass6: {
                required,
            },
        };
    },
};
</script>
