import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
import { store } from "./store";
import { vMaska } from "maska/vue";
import VueLazyload from "vue-lazyload";

const app = createApp(App);

app.use(router);
app.use(store);

// Можно добавить стандартное изображение-заглушку
const defaultImage = "https://via.placeholder.com/300x300?text=Loading";

app.use(VueLazyload, {
    preLoad: 1.3, // Коэффициент предварительной загрузки
    error: defaultImage, // Если не удалось загрузить картинку, показываем изображение-заглушку
    loading: defaultImage, // Изображение-заглушка, пока картинка загружается
    attempt: 1, // Количество попыток загрузки изображения
    lazyComponent: true, // Поддержка ленивой загрузки компонентов
});

app.directive("maska", vMaska);

app.mount("#app");
