import Axios from "axios";

const initialState = () => ({
    person_info: {},
    person_contract: {},
    person_pricelist: {},
    person_balance: {},
    person_schedule: {},
});

const state = initialState();

const getters = {
    person_info: (state) => {
        return state.person_info;
    },
    person_contract: (state) => {
        return state.person_contract;
    },
    person_pricelist: (state) => {
        return state.person_pricelist;
    },
    person_balance: (state) => {
        return state.person_balance;
    },
    person_schedule: (state) => {
        return state.person_schedule;
    },
};

const mutations = {
    GET_INFO: (state, payload) => {
        state.person_info = payload;
    },
    GET_CONTRACT: (state, payload) => {
        state.person_contract = payload;
    },
    GET_PRICELIST: (state, payload) => {
        state.person_pricelist = payload;
    },
    GET_BALANCE: (state, payload) => {
        state.person_balance = payload;
    },
    RESET(state) {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
};

const actions = {
    // sendPasswordChangeCode: async ({ dispatch, getters }) => {
    //     await dispatch("setTokenData");

    //     await Axios.post(
    //         "https://api.fitpass.ru/api/person/sendPasswordChangeCode2",
    //         {
    //             Login: "EDUARDF",
    //         },
    //         {
    //             headers: {
    //                 Authorization:
    //                     getters.token_data.scheme +
    //                     " " +
    //                     getters.token_data.token,
    //             },
    //         }
    //     ).then((response) => {
    //         console.log(response);
    //     });
    // },
    // changePassword: async (context, code, password) => {
    //     await Axios.post(
    //         "https://api.fitpass.ru/api/person/changePassword",
    //         {
    //             Login: "EDUARDF",
    //             Code: code,
    //             NewPassword: password,
    //         },
    //         {
    //             headers: {
    //                 Authorization:
    //                     localStorage.scheme + " " + localStorage.token,
    //             },
    //         }
    //     ).then((response) => {
    //         console.log(response);
    //     });
    // },

    personRegister: async ({ getters, dispatch }, data) => {
        await dispatch("setTokenData");

        await Axios.post(
            "https://api.fitpass.ru/api/person/register",
            {
                Login: data.login,
                MainPhone: getters.phone,
                NickName: data.nickname,
                FirstName: data.nickname.split(" ")[1],
                Patronymic: data.nickname.split(" ")[2],
                LastName: data.nickname.split(" ")[0],
                MainEmail: data.email,
                Password: data.password,
                PhoneConfirmationCode: getters.code,
                ResidenceCountryCode: "RU",
                ResidenceCityID: 1,
                LanguageCode: "ru-RU",
            },
            {
                headers: {
                    Authorization:
                        getters.token_data.scheme +
                        " " +
                        getters.token_data.token,
                },
            }
        ).then((response) => {
            console.log(response);
        });

        localStorage.login = data.login;
        localStorage.password = data.password;
    },

    getPersonInfo: async (context) => {
        let response = await Axios.get("https://api.fitpass.ru/api/person", {
            headers: {
                Authorization:
                    context.getters.token_login.scheme +
                    " " +
                    context.getters.token_login.token,
            },
        });

        console.log(response.data.Data);

        context.commit("GET_INFO", {
            data: response.data.Data,
        });
    },
    getPersonContract: async (context) => {
        let response = await Axios.get(
            "https://api.fitpass.ru/api/contract?club=" +
                context.getters.club_id,
            {
                headers: {
                    Authorization:
                        context.getters.token_login.scheme +
                        " " +
                        context.getters.token_login.token,
                },
            }
        );

        console.log(response.data.Data);

        context.commit("GET_CONTRACT", {
            data: response.data.Data,
        });
    },
    // getPersonContractPriceList: async (context) => {
    //     let response = await Axios.get(
    //         "https://api.fitpass.ru/api/contract/priceList?club=" +
    //             context.getters.club_id,
    //         {
    //             headers: {
    //                 Authorization:
    //                     context.getters.token_login.scheme +
    //                     " " +
    //                     context.getters.token_login.token,
    //             },
    //         }
    //     );

    //     console.log(response.data.Data);

    //     context.commit("GET_PRICELIST", {
    //         data: response.data.Data,
    //     });
    // },
    getPersonBalance: async (context) => {
        let response = await Axios.get(
            "https://api.fitpass.ru/api/account?club=" +
                context.getters.club_id,
            {
                headers: {
                    Authorization:
                        context.getters.token_login.scheme +
                        " " +
                        context.getters.token_login.token,
                },
            }
        );

        console.log(response.data.Data);

        context.commit("GET_BALANCE", {
            data: response.data.Data,
        });
    },
    reset({ commit }) {
        commit("RESET");
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
