<template>
    <div
        class="flex flex-col items-center justify-between h-screen pt-20 pb-32 px-6"
    >
        <img class="flex-initial" src="@/assets/images/auth/logo.png" alt="" />

        <div class="flex-initial">
            <p class="text-white font-Bodoni72osc text-52 leading-60 uppercase">
                Добро пожаловать!
            </p>
            <p class="text-white text-xl pt-6 pb-8">
                С возвращением к нам, в нашу дружную и спортивную компанию
            </p>
            <router-link
                class="block bg-white rounded-2xl text-22 text-center py-3"
                to="/login"
            >
                Войти
            </router-link>
        </div>
    </div>
</template>

<script setup></script>
