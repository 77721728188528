import Axios from "axios";

const initialState = () => ({
    token_data: {},
    token_login: {},
    phone: "",
    code: "",
});

const state = initialState();

const getters = {
    token_data: (state) => {
        return state.token_data;
    },
    token_login: (state) => {
        return state.token_login;
    },
    phone: (state) => {
        return state.phone;
    },
    code: (state) => {
        return state.code;
    },
};

const mutations = {
    SET_TOKEN: (state, payload) => {
        state.token_data = payload;
    },
    SET_LOGIN: (state, payload) => {
        state.token_login = payload;
    },
    SET_TIME: (state, payload) => {
        state.time = payload;
    },
    SET_PHONE: (state, payload) => {
        state.phone = payload;
    },
    SET_CODE: (state, payload) => {
        state.code = payload;
    },
    RESET(state) {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
};

const actions = {
    setTokenData: async ({ commit, getters }) => {
        const date = new Date();
        let time = date.getTime();

        if (time < getters.token_data.time + 1800) return;

        let response = await Axios.post("https://api.fitpass.ru/api/login", {
            SessionType: 1,
            APIKey: getters.api_key,
        });

        commit("SET_TOKEN", {
            scheme: response.data.Data.Scheme,
            token: response.data.Data.Token,
            time: time,
        });
    },

    setTokenLogin: async ({ commit, getters }, payload) => {
        const date = new Date();
        let time = date.getTime(),
            login,
            password;

        if (time < getters.token_data.time + 18000) return;

        if (!payload) {
            login = localStorage.login;
            password = localStorage.password;
        } else {
            const { auth_login, auth_password } = payload;

            login = auth_login;
            password = auth_password;
        }

        let response = await Axios.post("https://api.fitpass.ru/api/login", {
            SessionType: 2,
            Login: login,
            Password: password,
            APIKey: getters.api_key,
        });

        localStorage.login = login;
        localStorage.password = password;

        console.log({
            scheme: response.data.Data.Scheme,
            token: response.data.Data.Token,
        });

        commit("SET_LOGIN", {
            scheme: response.data.Data.Scheme,
            token: response.data.Data.Token,
            time: time,
        });
    },
    reset({ commit }) {
        commit("RESET");
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
