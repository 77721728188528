import { createStore } from "vuex";
//import Axios from "axios";
import sliders from "./modules/sliders";
import tokens from "./modules/tokens";
import person from "./modules/person";
import schedule from "./modules/schedule";
import lessons from "./modules/lessons";
import team from "./modules/team";
import rooms from "./modules/rooms";
import news from "./modules/news";
//import modules from "./modules";

export const store = createStore({
    state: {
        api_key: "b0b43c4c4a8941bfa9e163e5f6a370a3",
        club_id: "61f8cbfc-5127-41d6-aaa5-984591d2e068",
        data: [
            {
                PersonID: "00000000-0000-0000-0000-000000000000",
                DateOfBirth: "1990-01-02",
                Gender: 1,
                Language: {
                    Code: "ru-RU",
                    Name: "русский",
                },
                MainEmail: "zamurchalovrulit@yandex.miay",
                MainEmailConfirmed: true,
                MainPhone: "78005553535",
                MainPhoneConfirmed: true,
                NickName: "Котик Котиков З.",
                FirstName: "Котик",
                Patronymic: "Котиков",
                LastName: "Замурчалов",
                Login: "kotik",
                ResidenceCity: {
                    ID: 4,
                    Name: "Москва",
                },
                ResidenceCountry: {
                    Code: "RU",
                    Name: "Российская Федерация",
                },
            },
        ],
    },

    getters: {
        export_data: (state) => {
            return state.data;
        },
        api_key: (state) => {
            return state.api_key;
        },
        club_id: (state) => {
            return state.club_id;
        },
    },

    mutations: {},

    actions: {
        reset({ commit, modules }) {
            Object.keys(modules).forEach((moduleName) => {
                commit(`${moduleName}/RESET`);
            });
        },
    },

    modules: {
        sliders,
        tokens,
        person,
        schedule,
        team,
        lessons,
        rooms,
        news,
    },
});
