<template>
    <div
        v-if="!route.meta.footerHide"
        class="flex items-center justify-between max-w-md mx-auto px-6 py-4 sticky bottom-0 z-[999] bg-white shadow-[0_-10px_10px_-3px_rgba(0,0,0,0.05)]"
    >
        <router-link to="/" class="flex-initial">
            <img
                class="mx-auto pb-1"
                :src="pathActive('/', home_active, home)"
                alt=""
            />

            <p
                class="text-xs"
                :class="pathActive('/', 'text-purple', 'text-textgray')"
            >
                Главная
            </p>
        </router-link>

        <router-link to="/favorite" class="flex-initial">
            <img
                class="mx-auto pb-1"
                :src="pathActive('/favorite', hearth_active, hearth)"
                alt=""
            />
            <p
                class="text-xs"
                :class="pathActive('/favorite', 'text-purple', 'text-textgray')"
            >
                Избранное
            </p>
        </router-link>

        <router-link to="/schedule" class="flex-initial">
            <img
                class="mx-auto pb-1"
                :src="pathActive('/schedule', schedule_active, schedule)"
                alt=""
            />
            <p
                class="text-xs"
                :class="pathActive('/schedule', 'text-purple', 'text-textgray')"
            >
                Расписание
            </p>
        </router-link>

        <router-link to="/shop" class="flex-initial">
            <img
                class="mx-auto pb-1"
                :src="pathActive('/shop', basket_active, basket)"
                alt=""
            />
            <p
                class="text-xs"
                :class="pathActive('/shop', 'text-purple', 'text-textgray')"
            >
                Маркет
            </p>
        </router-link>

        <router-link to="/user" class="flex-initial">
            <img
                class="mx-auto pb-1"
                :src="pathActive('/user', profile_active, profile)"
                alt=""
            />
            <p
                class="text-xs"
                :class="pathActive('/user', 'text-purple', 'text-textgray')"
            >
                Профиль
            </p>
        </router-link>
    </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import home from "@/assets/images/icons/home.svg";
import home_active from "@/assets/images/icons/home_active.svg";
import hearth from "@/assets/images/icons/hearth.svg";
import hearth_active from "@/assets/images/icons/hearth_active.svg";
import schedule from "@/assets/images/icons/schedule.svg";
import schedule_active from "@/assets/images/icons/schedule_active.svg";
import basket from "@/assets/images/icons/basket.svg";
import basket_active from "@/assets/images/icons/basket_active.svg";
import profile from "@/assets/images/icons/profile.svg";
import profile_active from "@/assets/images/icons/profile_active.svg";

const route = useRoute();

const pathActive = (path, active, normal) => {
    return route.fullPath == path ? active : normal;
};
</script>
