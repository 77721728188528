<template>
    <transition name="slide-fade">
        <div
            v-if="props.notification"
            class="absolute w-full bg-background top-0 left-0 h-screen px-6 z-[9999]"
        >
            <div class="flex items-center justify-between py-6">
                <div class="flex-none">
                    <button class="block" @click="$emit('toggle')">
                        <img src="@/assets/images/icons/close.svg" alt="" />
                    </button>
                </div>

                <div class="flex-initial">
                    <span class="text-lg">Меню</span>
                </div>

                <div class="flex-initial w-[37px]"></div>
            </div>

            <div class="flex bg-white rounded-2xl p-6">
                <div class="flex-initial">
                    <img
                        class="pt-2 mr-4"
                        width="16"
                        src="@/assets/images/icons/time.svg"
                        alt=""
                    />
                </div>

                <div class="flex-initial">
                    <p class="text-22 font-medium pb-2">Напоминания</p>
                    <p class="text-sm">
                        Если не хотите пропустить тренировку, то включите
                        уведомления!
                    </p>
                </div>

                <div class="flex-initial"></div>
            </div>

            <div class="my-6">
                <p class="text-xl font-medium pb-8">
                    Воскресенье,
                    <span class="text-sm text-textgray font-normal"
                        >7 июля</span
                    >
                </p>

                <div class="flex items-center">
                    <p class="flex-1 text-sm">
                        Тренировка Acua ABS состоится завтра
                    </p>
                    <p class="flex-initial text-sm text-textgray">12:00</p>
                </div>

                <div class="w-full h-px my-4 bg-gray"></div>

                <div class="flex items-center">
                    <p class="flex-1 text-sm">
                        Бассейн закрыт, плавайте в слезах
                    </p>
                    <p class="flex-initial text-sm text-textgray">16:00</p>
                </div>

                <div class="w-full h-px my-4 bg-gray"></div>

                <div class="flex items-center">
                    <p class="flex-1 text-sm">
                        Тренировка Acua ABS состоится завтра
                    </p>
                    <p class="flex-initial text-sm text-textgray">12:00</p>
                </div>

                <div class="w-full h-px my-4 bg-gray"></div>

                <div class="flex items-center">
                    <p class="flex-1 text-sm">
                        Бассейн закрыт, плавайте в слезах
                    </p>
                    <p class="flex-initial text-sm text-textgray">16:00</p>
                </div>

                <div class="w-full h-px my-4 bg-gray"></div>
            </div>

            <div class="my-6">
                <p class="text-xl font-medium pb-7">
                    Суббота,
                    <span class="text-sm text-textgray font-normal"
                        >6 июля</span
                    >
                </p>

                <div class="flex items-center">
                    <p class="flex-1 text-sm">
                        Тренировка Acua ABS состоится завтра
                    </p>
                    <p class="flex-initial text-sm text-textgray">12:00</p>
                </div>

                <div class="w-full h-px my-4 bg-gray"></div>

                <div class="flex items-center">
                    <p class="flex-1 text-sm">
                        Бассейн закрыт, плавайте в слезах
                    </p>
                    <p class="flex-initial text-sm text-textgray">16:00</p>
                </div>

                <div class="w-full h-px my-4 bg-gray"></div>

                <div class="flex items-center">
                    <p class="flex-1 text-sm">
                        Тренировка Acua ABS состоится завтра
                    </p>
                    <p class="flex-initial text-sm text-textgray">12:00</p>
                </div>

                <div class="w-full h-px my-4 bg-gray"></div>

                <div class="flex items-center">
                    <p class="flex-1 text-sm">
                        Бассейн закрыт, плавайте в слезах
                    </p>
                    <p class="flex-initial text-sm text-textgray">16:00</p>
                </div>

                <div class="w-full h-px my-4 bg-gray"></div>
            </div>
        </div>
    </transition>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps(["notification"]);
</script>
