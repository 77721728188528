<template>
    <transition name="slide-fade">
        <div
            v-if="props.menu"
            class="fixed w-full top-0 left-0 bg-white h-screen px-6 z-[9999]"
        >
            <div class="flex items-center justify-between py-6">
                <div class="flex-none">
                    <button class="block" @click="emits('toggle')">
                        <img src="@/assets/images/icons/close.svg" alt="" />
                    </button>
                </div>

                <div class="flex-initial">
                    <span class="text-lg">Меню</span>
                </div>

                <div class="flex-initial w-[37px]"></div>
            </div>

            <div class="rounded-xl bg-purple relative p-5 pt-14 mt-12">
                <div
                    class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                >
                    <img
                        class="w-24 h-24 rounded-full object-cover border-4 border-white"
                        src="@/assets/images/profile/user.png"
                        alt=""
                    />
                </div>

                <p class="text-white text-center text-22 font-medium">
                    {{ props.userData.data.NickName }}
                </p>
            </div>

            <div class="mt-8 mb-6">
                <div class="ml-4">
                    <router-link to="/user" class="flex items-center pb-2">
                        <div class="flex-[0_1_12%]">
                            <img
                                class="h-5"
                                src="@/assets/images/icons/profile.svg"
                                alt=""
                            />
                        </div>

                        <p class="flex-1 text-lg">Профиль</p>
                    </router-link>

                    <router-link to="/regclass" class="flex items-center pb-2">
                        <div class="flex-[0_1_12%]">
                            <img
                                width="22px"
                                src="@/assets/images/icons/schedule.svg"
                                alt=""
                            />
                        </div>

                        <p class="flex-1 text-lg">Мои записи</p>
                    </router-link>

                    <router-link to="/news" class="flex items-center pb-2">
                        <div class="flex-[0_1_12%]">
                            <img
                                width="22px"
                                src="@/assets/images/icons/news.svg"
                                alt=""
                            />
                        </div>

                        <p class="flex-1 text-lg">Новости</p>
                    </router-link>

                    <router-link to="/promotion" class="flex items-center pb-2">
                        <div class="flex-[0_1_12%]">
                            <img
                                width="22px"
                                src="@/assets/images/icons/star.svg"
                                alt=""
                            />
                        </div>

                        <p class="flex-1 text-lg">Акции</p>
                    </router-link>

                    <router-link to="/team" class="flex items-center pb-2">
                        <div class="flex-[0_1_12%]">
                            <img
                                src="@/assets/images/icons/goblet.svg"
                                alt=""
                            />
                        </div>

                        <p class="flex-1 text-lg">Тренеры</p>
                    </router-link>
                </div>

                <div class="h-px w-full bg-gray my-9"></div>

                <div class="ml-4">
                    <router-link to="/feedback" class="flex items-center pb-12">
                        <div class="flex-[0_1_12%]">
                            <img
                                src="@/assets/images/icons/contact.svg"
                                alt=""
                            />
                        </div>

                        <p class="flex-1 text-lg">Связь с клубом</p>
                    </router-link>

                    <!-- <router-link to="/" class="flex items-center pb-2">
                        <div class="flex-[0_1_12%]"></div>

                        <p class="flex-1 text-lg">О клубе</p>
                    </router-link>

                    <router-link to="/" class="flex items-center pb-12">
                        <div class="flex-[0_1_12%]">
                            <img
                                src="@/assets/images/icons/settings_small.svg"
                                alt=""
                            />
                        </div>

                        <p class="flex-1 text-lg">Настройки</p>
                    </router-link> -->

                    <router-link to="/" class="flex items-center">
                        <div class="flex-[0_1_12%]">
                            <img src="@/assets/images/icons/exit.svg" alt="" />
                        </div>

                        <p class="flex-1 text-lg">Выйти из приложения</p>
                    </router-link>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup>
import { defineProps, defineEmits, watch } from "vue";
import { useRoute } from "vue-router";

const props = defineProps(["menu", "userData"]);
const emits = defineEmits(["toggle"]);
const route = useRoute();

watch(route, () => {
    if (props.menu == true) emits("toggle");
});
</script>
