<template>
    <div class="px-6">
        <!-- <content-loader
            viewBox="0 0 350 230"
            speed="2"
            primary-color="blue"
            secondary-color="red"
        >
            <rect x="0" y="10" rx="4" ry="4" width="350" height="230" />
            <rect x="0" y="30" rx="3" ry="3" width="250" height="13" />
            <rect x="0" y="50" rx="3" ry="3" width="200" height="13" />
        </content-loader> -->
        <!-- <facebook-loader primary-color="blue" secondary-color="red" />
        <code-loader primary-color="blue" secondary-color="red" />
        <bullet-list-loader primary-color="blue" secondary-color="red" />
        <instagram-loader primary-color="blue" secondary-color="red" />
        <list-loader primary-color="blue" secondary-color="red" /> -->

        <swiper
            :modules="modules"
            :slides-per-view="1"
            :space-between="10"
            :pagination="{ clickable: true }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            class="mb-6"
        >
            <swiper-slide
                v-for="(value, index) in $store.getters.main"
                :key="index"
                class="mb-2"
            >
                <router-link :to="value.url">
                    <img width="100%" :src="value.image" :alt="value.name" />
                </router-link>
            </swiper-slide>
        </swiper>

        <div class="mb-8">
            <p class="text-22 font-medium pb-8">Клубные карты</p>

            <content-loader
                v-if="!person_contract.data"
                class="w-full h-60"
                viewBox="0 0"
                speed="2"
                primary-color="#f3f3f3"
                secondary-color="#ecebeb"
            >
                <rect x="0" y="0" rx="16" width="100%" height="100%" />
            </content-loader>

            <swiper
                v-else
                :modules="modules"
                :slides-per-view="'auto'"
                :space-between="10"
                :free-mode="true"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
                class="swiper__auto"
            >
                <swiper-slide
                    v-for="(value, index) in person_contract.data"
                    :key="index"
                    class="relative w-fit"
                >
                    <img
                        src="@/assets/images/slider/card/card-item.png"
                        alt=""
                    />

                    <div
                        class="absolute flex flex-col text-white top-0 p-5 w-full h-full"
                    >
                        <div class="flex-1">
                            <router-link
                                to="/agreement"
                                class="flex items-center"
                            >
                                <div class="flex-1">
                                    <p class="text-sm">
                                        Договор №{{ value.CardNumber }}
                                    </p>
                                </div>

                                <div class="flex-initial">
                                    <img
                                        src="@/assets/images/icons/arrow_white.svg"
                                        alt=""
                                    />
                                </div>
                            </router-link>

                            <p class="text-32 font-medium uppercase mb-2">
                                {{ value.Name }}
                            </p>
                        </div>

                        <div class="flex-1">
                            <div class="flex">
                                <div class="flex-auto pr-4">
                                    <p class="text-sm">Осталось визитов:</p>
                                    <p class="text-32 leading-8 font-medium">
                                        {{ value.AvailableVisitsLeft }}
                                    </p>

                                    <router-link
                                        to="/agreement"
                                        class="w-full py-2 mt-3 text-sm text-black bg-white rounded-md block text-center"
                                    >
                                        Продлить
                                    </router-link>
                                </div>

                                <div class="flex-auto">
                                    <p class="text-sm">Дней заморозки:</p>
                                    <p class="text-32 leading-8 font-medium">
                                        {{ value.CanSuspendDays }}
                                    </p>

                                    <router-link
                                        v-if="value.CanSuspend"
                                        to="/freeze"
                                        class="w-full py-2 mt-3 text-sm text-black bg-white rounded-md block text-center"
                                    >
                                        Заморозить
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>

                <swiper-slide class="relative w-fit">
                    <img
                        src="@/assets/images/slider/card/card-item.png"
                        alt=""
                    />

                    <div
                        class="absolute flex flex-col text-white top-0 p-5 w-full h-full"
                    >
                        <div class="flex-1">
                            <router-link
                                to="/agreement"
                                class="flex items-center"
                            >
                                <div class="flex-1">
                                    <p class="text-sm">Договор №123123123123</p>
                                </div>

                                <div class="flex-initial">
                                    <img
                                        src="@/assets/images/icons/arrow_white.svg"
                                        alt=""
                                    />
                                </div>
                            </router-link>

                            <p class="text-32 font-medium uppercase mb-2">
                                lolo
                            </p>
                        </div>

                        <div class="flex-1">
                            <div class="flex">
                                <div class="flex-auto pr-4">
                                    <p class="text-sm">Осталось визитов:</p>
                                    <p class="text-32 leading-8 font-medium">
                                        12
                                    </p>

                                    <router-link
                                        to="/agreement"
                                        class="w-full py-2 mt-3 text-sm text-black bg-white rounded-md block text-center"
                                    >
                                        Продлить
                                    </router-link>
                                </div>

                                <div class="flex-auto">
                                    <p class="text-sm">Дней заморозки:</p>
                                    <p class="text-32 leading-8 font-medium">
                                        13
                                    </p>

                                    <router-link
                                        to="/freeze"
                                        class="w-full py-2 mt-3 text-sm text-black bg-white rounded-md block text-center"
                                    >
                                        Заморозить
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>

        <div class="pb-6">
            <div class="flex items-center pb-6">
                <p class="flex-1 text-22 font-medium">Ближайшая тренировка</p>

                <div class="flex-initial">
                    <router-link to="/schedule" class="flex items-center">
                        <p class="flex-1 pr-3">Все</p>
                        <img
                            class="flex-initial"
                            src="@/assets/images/icons/arrow_gray.svg"
                            alt=""
                        />
                    </router-link>
                </div>
            </div>

            <swiper
                v-if="
                    Object.keys(schedule).length > 0 &&
                    Object.keys(lessons).length > 0 &&
                    Object.keys(team).length > 0
                "
                :modules="modules"
                :slides-per-view="1"
                :space-between="10"
                :pagination="{ clickable: true }"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
            >
                <swiper-slide
                    v-for="(value, index) in schedule[today][getTimeNow()]"
                    :key="index"
                    class="bg-white rounded-xl p-4 mb-2"
                >
                    <div
                        class="bg-background flex items-center rounded-xl py-2"
                    >
                        <div class="flex-1 pl-5">
                            <p class="text-xl font-medium">
                                {{
                                    replaceName(
                                        lessons[value.LessonTypeID].Name
                                    )
                                }}
                            </p>
                        </div>

                        <div class="flex-initial text-center pr-3">
                            <p class="text-lg font-medium">
                                {{ value.BeginTime }}
                            </p>
                            <p class="text-sm text-dark">
                                {{
                                    lessonLong(
                                        today,
                                        value.BeginTime,
                                        value.EndTime
                                    )
                                }}
                                мин
                            </p>
                        </div>
                    </div>

                    <div class="flex items-center mt-3">
                        <div class="flex-initial">
                            <img
                                width="31px"
                                src="@/assets/images/trainer/trainer.png"
                                alt=""
                                class="rounded-full object-none object-top h-[31px] mr-3"
                            />
                        </div>

                        <div class="flex-initial">
                            <p>
                                {{ team[value.MasterID].LastName }}
                                {{ team[value.MasterID].FirstName }}
                            </p>
                        </div>

                        <div class="flex-initial px-2">
                            <p>|</p>
                        </div>

                        <div class="flex-initial">
                            <p>{{ rooms[value.RoomID] }}</p>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>

            <content-loader
                v-else
                class="w-full h-40"
                speed="2"
                primary-color="#f3f3f3"
                secondary-color="#ecebeb"
            >
                <rect x="0" y="0" rx="16" width="100%" height="100%" />
            </content-loader>
        </div>

        <div class="pb-6">
            <p class="text-22 font-medium pb-8">Для вас</p>

            <router-link to="/schedule" class="flex items-center">
                <img
                    class="flex-initial pr-5"
                    src="@/assets/images/icons/schedule.svg"
                    alt=""
                />
                <p class="flex-1 text-lg">Расписание</p>
                <img
                    class="flex-initial"
                    src="@/assets/images/icons/arrow_black.svg"
                    alt=""
                />
            </router-link>

            <div class="w-full h-px my-4 bg-gray"></div>

            <router-link to="/regclass" class="flex items-center">
                <img
                    class="flex-initial pr-5"
                    src="@/assets/images/icons/weight.svg"
                    alt=""
                />
                <p class="flex-1 text-lg">Запись на персональную тренировку</p>
                <img
                    class="flex-initial"
                    src="@/assets/images/icons/arrow_black.svg"
                    alt=""
                />
            </router-link>

            <div class="w-full h-px my-4 bg-gray"></div>

            <router-link to="/" class="flex items-center">
                <img
                    class="flex-initial pr-5"
                    src="@/assets/images/icons/basket.svg"
                    alt=""
                />
                <p class="flex-1 text-lg">Маркет услуг и товаров</p>
                <img
                    class="flex-initial"
                    src="@/assets/images/icons/arrow_black.svg"
                    alt=""
                />
            </router-link>

            <div class="w-full h-px my-4 bg-gray"></div>

            <router-link to="/promotion" class="flex items-center">
                <img
                    class="flex-initial pr-5"
                    src="@/assets/images/icons/star.svg"
                    alt=""
                />
                <p class="flex-1 text-lg">Акции</p>
                <img
                    class="flex-initial"
                    src="@/assets/images/icons/arrow_black.svg"
                    alt=""
                />
            </router-link>

            <div class="w-full h-px mt-4 bg-gray"></div>
        </div>

        <p class="text-22 font-medium pb-6">Новости</p>

        <swiper
            :modules="modules"
            :slides-per-view="1"
            :space-between="10"
            :pagination="{ clickable: true }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            class="mb-6"
        >
            <swiper-slide v-for="(item, index) in news" :key="index">
                <router-link :to="'/news/' + item.alias">
                    <div class="rounded-2xl bg-white mb-5">
                        <div class="flex items-center">
                            <div class="flex-1">
                                <img
                                    class="rounded-xl object-cover"
                                    :src="
                                        'https://lasalute.ru/' + item.tvs.images
                                    "
                                    alt=""
                                />
                            </div>

                            <div class="flex-1 p-2">
                                <div class="flex items-baseline">
                                    <p class="flex-1">
                                        {{ item.pagetitle }}
                                    </p>

                                    <img
                                        class="flex-initial pr-3"
                                        src="@/assets/images/icons/arrow_black.svg"
                                        alt=""
                                    />
                                </div>

                                <p
                                    v-if="item.tvs.newsPrice"
                                    v-html="item.tvs.newsPrice + ' рублей'"
                                    class="font-medium"
                                ></p>
                                <p v-else class="font-medium">Бесплатно</p>
                            </div>
                        </div>
                    </div>
                </router-link>
            </swiper-slide>
        </swiper>
    </div>

    <router-view />
</template>

<script setup>
import { computed, watch } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, FreeMode } from "swiper/modules";
import { useStore } from "vuex";
import { format, closestTo, parseISO, isAfter } from "date-fns";
import { replaceName, lessonLong } from "@/functions/function";
import { ContentLoader } from "vue-content-loader";
import "swiper/css";

const store = useStore();
const modules = [Pagination, FreeMode];

store.dispatch("setTeamData");
store.dispatch("setLessonsData");
store.dispatch("setScheduleData");
store.dispatch("setNewsData");

const person_contract = computed(() => {
    return store?.getters?.person_contract;
});
const schedule = computed(() => {
    return store?.getters?.schedule;
});
const team = computed(() => {
    return store?.getters?.team;
});
const lessons = computed(() => {
    return store?.getters?.lessons;
});
const rooms = computed(() => {
    return store?.getters?.rooms;
});
const news = computed(() => {
    return store?.getters?.news;
});

let now = computed(() => {
        return new Date();
    }),
    today = format(now.value, "y-MM-dd"),
    times = [];

const getTimeNow = () => {
    for (const [key] of Object.entries(schedule.value[today])) {
        let time = parseISO(today + "T" + key);
        if (isAfter(time, now.value)) times.push(time);
    }

    return format(closestTo(now.value, times), "HH:mm");
};

watch(now, () => {
    console.log(now.value);
});
</script>
