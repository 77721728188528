<template>
    <router-view @go-back="$emit('goBack')" />

    <div class="px-6 mb-6">
        <!-- <div class="flex mb-6">
            <div
                class="flex-initial rounded-lg py-4 px-6 bg-purple text-white text-sm text-center mr-2"
            >
                <p>Все</p>
            </div>

            <div
                class="flex-1 rounded-lg py-4 bg-white text-sm text-center mr-2"
            >
                <p>Мероприятия</p>
            </div>

            <div class="flex-1 rounded-lg py-4 bg-white text-sm text-center">
                <p>Информация</p>
            </div>
        </div> -->

        <div class="grid gap-2 grid-cols-2">
            <div
                v-for="(item, index) in news"
                :key="index"
                class="rounded-xl bg-white"
            >
                <router-link :to="'/news/' + item.alias">
                    <img
                        class="rounded-xl object-cover w-full"
                        :src="'https://lasalute.ru/' + item.tvs.images"
                        alt=""
                    />

                    <div class="p-4">
                        <p class="text-center mb-6">
                            {{ item.pagetitle }}
                        </p>

                        <p
                            v-if="item.tvs.newsPrice"
                            v-html="item.tvs.newsPrice + ' рублей'"
                            class="text-center font-medium"
                        ></p>
                        <p v-else class="text-center font-medium">Бесплатно</p>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
// import { useRoute } from "vue-router";

// const route = useRoute();
const store = useStore();

store.dispatch("setNewsData");

const news = computed(() => {
    return store?.getters?.news;
});
</script>
