<template>
    <router-view @go-back="emit('goBack')" />

    <div class="px-6">
        <div class="rounded-xl bg-purple relative p-5 pt-14 mt-16">
            <div
                class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            >
                <img
                    class="w-24 h-24 rounded-full object-cover border-4 border-white"
                    src="@/assets/images/profile/user.png"
                    alt=""
                />

                <router-link to="/user/profile">
                    <img
                        class="absolute bottom-1 right-1 border-4 border-solid border-white rounded-full bg-white"
                        src="@/assets/images/icons/gear.svg"
                        alt=""
                    />
                </router-link>
            </div>

            <p
                v-if="Object.keys(userData).length > 0"
                class="text-white text-center text-22 font-medium"
            >
                {{ userData.data.NickName }}
            </p>
        </div>

        <div class="flex pt-4">
            <router-link class="flex-auto pr-3" to="/regclass">
                <img
                    width="100%"
                    src="@/assets/images/profile/registration.png"
                    alt=""
                />
            </router-link>

            <router-link class="flex-auto" to="/shop">
                <img
                    width="100%"
                    src="@/assets/images/profile/shopping.png"
                    alt=""
                />
            </router-link>
        </div>

        <div class="py-8">
            <p class="text-xl font-medium pb-8">Мой кабинет</p>

            <router-link class="flex items-center" to="/services">
                <img
                    class="flex-initial pr-5"
                    src="@/assets/images/icons/weight.svg"
                    alt=""
                />
                <p class="flex-1 text-lg">Мои абонементы и услуги</p>
                <img
                    class="flex-initial"
                    src="@/assets/images/icons/arrow_black.svg"
                    alt=""
                />
            </router-link>

            <div class="w-full h-px my-4 bg-gray"></div>

            <router-link class="flex items-center" to="/favorite">
                <img
                    class="flex-initial pr-5"
                    src="@/assets/images/icons/hearth.svg"
                    alt=""
                />
                <p class="flex-1 text-lg">Избранное</p>
                <img
                    class="flex-initial"
                    src="@/assets/images/icons/arrow_black.svg"
                    alt=""
                />
            </router-link>

            <div class="w-full h-px my-4 bg-gray"></div>

            <router-link class="flex items-center" to="/">
                <img
                    class="flex-initial pr-5"
                    src="@/assets/images/icons/settings.svg"
                    alt=""
                />
                <p class="flex-1 text-lg">Настройки</p>
                <img
                    class="flex-initial"
                    src="@/assets/images/icons/arrow_black.svg"
                    alt=""
                />
            </router-link>

            <div class="w-full h-px mt-4 bg-gray"></div>
        </div>

        <router-link class="flex items-center" to="/">
            <p class="flex-1 text-lg">Вакансии</p>
            <img
                class="flex-initial"
                src="@/assets/images/icons/arrow_black.svg"
                alt=""
            />
        </router-link>

        <div class="w-full h-px mt-4 bg-gray"></div>

        <router-link class="flex items-center py-8" to="/feedback">
            <div class="flex-initial">
                <img
                    class="pr-8"
                    src="@/assets/images/icons/support.svg"
                    alt=""
                />
            </div>

            <div class="flex-1">
                <p class="text-sm text-textgray">Есть вопросы?</p>
                <p class="text-lg">Служба поддержки</p>
            </div>
        </router-link>

        <div class="w-full h-px bg-gray"></div>

        <div class="flex items-center py-9">
            <div class="flex-1">
                <p class="text-lg">Выйти из профиля</p>
            </div>

            <div class="flex-initial">
                <img src="@/assets/images/icons/exit.svg" alt="" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, defineEmits } from "vue";
import { useStore } from "vuex";

const emit = defineEmits(["goBack"]);
const store = useStore();

const userData = computed(() => {
    return store?.getters?.person_info;
});
</script>
