import Axios from "axios";
import { replaceName } from "@/functions/function";

const initialState = () => ({
    lessons: {},
    lessons_name: [],
});

const state = initialState();

const getters = {
    lessons: (state) => {
        return state.lessons;
    },
    lessons_name: (state) => {
        return state.lessons_name;
    },
};

const mutations = {
    SET_LESSONS: (state, payload) => {
        state.lessons = payload;
    },
    SET_LESSONS_NAME: (state, payload) => {
        state.lessons_name = payload;
    },
};

const actions = {
    setLessonsData: async ({ commit, getters }) => {
        if (Object.keys(getters.lessons).length > 0) return;

        let lessons = {},
            lessons_name = [],
            response = await Axios.get(
                "https://api.fitpass.ru/api/lesson/lessons?club=308"
            );

        response.data.Data.forEach((data) => {
            if (data.Group) {
                lessons_name.push({
                    id: data.ID,
                    name: replaceName(data.Name),
                });
                lessons[data.ID] = data;
            }
        });

        lessons_name.sort(function (a, b) {
            let textA = a.name.toUpperCase();
            let textB = b.name.toUpperCase();

            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        console.log(lessons);
        console.log(lessons_name);

        commit("SET_LESSONS", lessons);
        commit("SET_LESSONS_NAME", lessons_name);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
