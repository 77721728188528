<template>
    <form
        @submit.prevent="changePassword"
        class="flex flex-col items-center justify-between h-screen pb-16 px-6"
    >
        <div class="flex-initial">
            <img src="@/assets/images/auth/logo-short.png" alt="" />

            <p
                class="text-white font-Bodoni72osc text-46 leading-54 uppercase pt-6"
            >
                Почти закончили
            </p>
            <p class="text-white text-xl pt-6 pb-8">
                Вы только зарегистрировались, придумайте пароль для вашего
                аккаунта
            </p>

            <p class="text-white text-lg">Введите смс код</p>

            <div class="flex flex-shrink flex-wrap pb-4">
                <div class="flex-1">
                    <input
                        maxlength="1"
                        v-model="$validate.pass1.$model"
                        class="w-full bg-white text-center rounded-2xl text-22"
                        type="text"
                        @input="focusNext"
                    />
                </div>

                <div class="flex-1">
                    <input
                        maxlength="1"
                        v-model="$validate.pass2.$model"
                        class="w-full bg-white text-center rounded-2xl text-22"
                        type="text"
                        @input="focusNext"
                    />
                </div>

                <div class="flex-1">
                    <input
                        maxlength="1"
                        v-model="$validate.pass3.$model"
                        class="w-full bg-white text-center rounded-2xl text-22"
                        type="text"
                        @input="focusNext"
                    />
                </div>

                <div class="flex-1">
                    <input
                        maxlength="1"
                        v-model="$validate.pass4.$model"
                        class="w-full bg-white text-center rounded-2xl text-22"
                        type="text"
                        @input="focusNext"
                    />
                </div>

                <div class="flex-1">
                    <input
                        maxlength="1"
                        v-model="$validate.pass5.$model"
                        class="w-full bg-white text-center rounded-2xl text-22"
                        type="text"
                        @input="focusNext"
                    />
                </div>

                <div class="flex-1">
                    <input
                        maxlength="1"
                        v-model="$validate.pass6.$model"
                        class="w-full bg-white text-center rounded-2xl text-22"
                        type="text"
                    />
                </div>
            </div>

            <input
                class="w-full bg-white rounded-2xl text-22 py-3 pl-8"
                type="text"
                v-model="$validate.password.$model"
                placeholder="Пароль"
                name="password"
                @blur="$validate.password.$touch()"
            />

            <span v-if="$validate.password.$error" class="text-white">
                Неправильно набран пароль
            </span>
        </div>

        <div class="flex-initial">
            <button
                class="w-full bg-white rounded-2xl text-22 text-center py-3 mb-3"
                type="submit"
                :disabled="$validate.$invalid"
            >
                Передать пароль
            </button>

            <p class="text-white text-xl text-center">
                Нажимая кнопку я соглашась на правила чего-то там
            </p>
        </div>
    </form>
</template>

<script>
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useStore } from "vuex";
//import { useRouter } from "vue-router";

export default {
    setup() {
        let store = useStore(),
            //router = useRouter(),
            pass1 = ref(""),
            pass2 = ref(""),
            pass3 = ref(""),
            pass4 = ref(""),
            pass5 = ref(""),
            pass6 = ref(""),
            code = ref(""),
            password = ref("");

        store.dispatch("sendPasswordChangeCode");

        const changePassword = () => {
            code.value =
                pass1.value.toString() +
                pass2.value.toString() +
                pass3.value.toString() +
                pass4.value.toString() +
                pass5.value.toString() +
                pass6.value.toString();

            store.dispatch("changePassword", code.value, password.value);
            //router.push("/");
        };

        return {
            store,
            $validate: useVuelidate(),
            pass1,
            pass2,
            pass3,
            pass4,
            pass5,
            pass6,
            password,
            changePassword,
        };
    },
    validations() {
        return {
            pass1: {
                required,
            },
            pass2: {
                required,
            },
            pass3: {
                required,
            },
            pass4: {
                required,
            },
            pass5: {
                required,
            },
            pass6: {
                required,
            },
            password: {
                required,
            },
        };
    },
};
</script>
