import Axios from "axios";

const initialState = () => ({
    team: {},
});

const state = initialState();

const getters = {
    team: (state) => {
        return state.team;
    },
};

const mutations = {
    SET_TEAM: (state, payload) => {
        state.team = payload;
    },
};

const actions = {
    setTeamData: async ({ commit, getters }) => {
        if (Object.keys(getters.team).length > 0) return;

        let masters = {},
            response = await Axios.get(
                "https://api.fitpass.ru/api/lesson/masters?club=308"
            );

        response.data.Data.forEach((data) => {
            if (data.Description != null)
                data.Description = data.Description.replace(/\r\n/g, "<br />");

            masters[data.ID] = data;
        });

        console.log(masters);

        commit("SET_TEAM", masters);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
