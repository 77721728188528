<template>
    <div class="px-6">
        <p class="text-22 font-medium mt-9 mb-7">Бесплатные дни</p>

        <div class="flex items-center mb-2">
            <p class="flex-1 text-sm">Доступно всего</p>
            <p class="flex-initial text-sm">31</p>
        </div>

        <div class="flex items-center mb-2">
            <p class="flex-1 text-sm">Израсходовано</p>
            <p class="flex-initial text-sm">10</p>
        </div>

        <div class="flex items-center mb-2">
            <p class="flex-1 text-sm font-medium">Осталось</p>
            <p class="flex-initial text-sm font-medium">21</p>
        </div>

        <div class="text-center mt-8 mb-16">
            <button class="rounded-xl text-white bg-purple px-10 py-2">
                Докупить дни
            </button>
        </div>

        <p class="text-22 font-medium mb-8">Дата и дни заморозки</p>

        <input
            class="w-full bg-white rounded-xl text-sm text-dark py-4 px-4 mb-4"
            type="date"
        />

        <select
            class="w-full bg-white rounded-xl text-sm text-dark py-4 px-4"
            name=""
            id=""
        >
            <option value="7">7 дней</option>
            <option value="30">30 дней</option>
        </select>

        <input
            type="submit"
            value="Заморозить"
            class="w-full bg-purple rounded-xl text-lg font-medium text-white py-3 text-center mt-8"
        />
    </div>
</template>

<script setup></script>
