<template>
    <div class="fixed top-0 left-0 bg-background w-full h-screen px-6 z-[1999]">
        <div class="flex items-center justify-between py-6">
            <div class="flex-none">
                <button class="block" @click="$emit('goBack')">
                    <img src="@/assets/images/icons/back.svg" alt="" />
                </button>
            </div>

            <div class="flex-initial">
                <span class="text-lg">Меню</span>
            </div>

            <div class="flex-initial w-[37px]"></div>
        </div>

        <form action="" class="flex flex-col justify-center h-[75vh] mt-8 mb-5">
            <div class="bg-white rounded-xl px-5 py-3 mb-2">
                <span class="block text-xs text-gray">Имя</span>
                <input
                    type="text"
                    :value="userData.data.FirstName"
                    name="name"
                    class="text-sm w-full outline-none"
                />
            </div>

            <div class="bg-white rounded-xl px-5 py-3 mb-2">
                <span class="block text-xs text-gray">Фамилия</span>
                <input
                    type="text"
                    :value="userData.data.LastName"
                    name="second_name"
                    class="text-sm w-full outline-none"
                />
            </div>

            <div class="bg-white rounded-xl px-5 py-3 mb-2">
                <span class="block text-xs text-gray">Отчество</span>
                <input
                    type="text"
                    :value="userData.data.Patronymic"
                    name="middle_name"
                    class="text-sm w-full outline-none"
                />
            </div>

            <div class="flex">
                <div class="flex-auto pr-1">
                    <div class="bg-white rounded-xl px-5 py-3 mb-2">
                        <span class="block text-xs text-gray"
                            >День рождения</span
                        >
                        <input
                            type="date"
                            :value="userData.data.DateOfBirth"
                            name="dob"
                            class="text-sm w-full outline-none"
                        />
                    </div>
                </div>

                <div class="flex-auto pl-1">
                    <div class="bg-white rounded-xl px-5 py-3 mb-2">
                        <span class="block text-xs text-gray">Пол</span>
                        <input
                            type="text"
                            :value="gender"
                            name="sex"
                            class="text-sm w-full outline-none"
                        />
                    </div>
                </div>
            </div>

            <div class="bg-white rounded-xl px-5 py-3 mb-2">
                <span class="block text-xs text-gray">Телефон</span>
                <input
                    type="text"
                    :value="phone"
                    name="phone"
                    class="text-sm w-full outline-none"
                />
            </div>

            <div class="bg-white rounded-xl px-5 py-3 mb-2">
                <span class="block text-xs text-gray">Почта</span>
                <input
                    type="text"
                    :value="userData.data.MainEmail"
                    name="mail"
                    class="text-sm w-full outline-none"
                />
            </div>

            <div class="flex justify-center my-8">
                <a class="flex-initial text-pink underline text-sm" href="#"
                    >Удалить акаунт</a
                >
            </div>

            <input
                type="submit"
                value="Сохранить"
                class="w-full bg-purple rounded-xl text-lg font-medium text-white py-3 text-center"
            />
        </form>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const userData = computed(() => {
    return store?.getters?.person_info;
});

const phoneFormat = (s) => {
    const startsWith = "+7";

    let phone = s.replace(/[^0-9]/g, "");

    return phone.replace(
        /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/g,
        `${startsWith} ($2) $3 $4 $5`
    );
};

let gender, phone;

if (userData.value.data.Gender == 1) gender = "Мужской";
else gender = "Женский";

phone = phoneFormat(userData.value.data.MainPhone);
</script>
