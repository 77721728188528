<template>
    <div class="flex pb-6">
        <div class="flex-1 border-b border-purple py-3">
            <p class="text-xs font-medium text-purple text-center">
                Новое обращение
            </p>
        </div>

        <div class="flex-1 border-b border-dark py-3">
            <p class="text-xs text-dark text-center">Архив</p>
        </div>
    </div>

    <div class="px-6">
        <p class="text-xs mb-1">Тема обращения</p>
        <select
            class="w-full text-sm bg-white text-textgray rounded-2xl pl-5 py-3 mb-4"
            name=""
            id=""
        >
            <option selected disabled>Выберите тему</option>
        </select>

        <p class="text-xs mb-1">Содержание обращения</p>
        <select
            class="w-full text-sm bg-white text-textgray rounded-2xl pl-5 py-3 mb-4"
            name=""
            id=""
        >
            <option selected disabled>Выберите раздел</option>
        </select>

        <p class="text-xs mb-1">Текст обращения</p>
        <textarea
            class="w-full h-[300px] text-sm bg-white text-textgray rounded-2xl pl-5 pt-3 mb-10 h"
            name=""
            id=""
        >
Добрый день, хочу обратиться с вопросом</textarea
        >

        <button class="w-full text-lg text-white bg-purple rounded-2xl py-3">
            Отправить
        </button>
    </div>
</template>

<script setup></script>
