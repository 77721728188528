import mainImg from "@/assets/images/slider/main/slider-item.png";
import cardImg from "@/assets/images/slider/card/card-item.png";

const state = {
    main: [
        {
            name: "Поменяй фитнес-клуб",
            image: mainImg,
            url: "/promotion/1",
        },
        {
            name: "Поменяй фитнес-клуб",
            image: mainImg,
            url: "/promotion/2",
        },
        {
            name: "Поменяй фитнес-клуб",
            image: mainImg,
            url: "/promotion/3",
        },
    ],
    card: [
        {
            card_name: "Free Style",
            agreement: "39067745",
            image: cardImg,
            visits_left: "40",
            freeze_day: "7",
        },
        {
            card_name: "Free Style",
            agreement: "39067745",
            image: cardImg,
            visits_left: "40",
            freeze_day: "7",
        },
    ],
};

const getters = {
    main: (state) => {
        return state.main;
    },
    card: (state) => {
        return state.card;
    },
};

const mutations = {};
const actions = {};

export default {
    state,
    getters,
    mutations,
    actions,
};
