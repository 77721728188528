<template>
    <!-- <p><strong>Текущий путь маршрута:</strong> {{ $route.path }}</p> -->

    <div
        class="max-w-md mx-auto min-h-[calc(100vh-80px)] bg-cover relative"
        :style="{ 'background-image': 'url(' + back_img + ')' }"
    >
        <main-header :user-data="userData" @go-back="goBack" />

        <router-view @go-back="goBack" />
    </div>

    <footer-menu />
</template>

<script setup>
import MainHeader from "./components/templates/MainHeader.vue";
import FooterMenu from "./components/templates/MainFooter.vue";
import { computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const route = useRoute();
const router = useRouter();
const token = computed(() => {
    return store.getters.token_login;
});

if (token.value) {
    if (localStorage.login && localStorage.password)
        store.dispatch("setTokenLogin");
    else router.push("/admin");
}

watch(token, () => {
    console.log("get token");

    store.dispatch("getPersonInfo");
    store.dispatch("getPersonContract");
    //store.dispatch("getPersonContractPriceList");
    store.dispatch("getPersonBalance");
    store.dispatch("getPersonSchedule");
});

const back_img = computed(() => {
    if (route.meta.img) return require("@/assets/images" + route.meta.img);
    else return "/";
});

const goBack = () => router.back();

const userData = computed(() => {
    return store?.getters?.person_info;
});
</script>
