import { getUnixTime } from "date-fns";

export function replaceName(name) {
    let newName = "";
    newName = name.replace(/ (\d+) ([мин]+)/u, "");
    newName = newName.replace(/ (\d+)/, "");
    newName = newName.replace(/\s+/g, " ").trim();

    return newName;
}

export function navActivate(index) {
    let items = document.querySelectorAll(".list__nav--item");

    listChangeDisplay(items, "none");

    if (!items[index]) return;

    items[index].style.display = "block";
}

export function listChangeDisplay(list, on_change) {
    list.forEach((element) => {
        element.style.display = on_change;
    });
}

export function elementChangeDisplay(element, on_change) {
    element.style.display = on_change;
}

export function timeChangeDisplay(list) {
    list.forEach((element) => {
        let flag = false;

        Array.from(element.children[1].children).forEach((block) => {
            if (block.style.display == "block") flag = true;
        });

        if (!flag) elementChangeDisplay(element, "none");
    });
}

export function lessonLong(date, begin, end) {
    return (
        (getUnixTime(date + " " + end) - getUnixTime(date + " " + begin)) / 60
    );
}
