<template>
    <div
        class="fixed top-0 left-0 bg-background w-full h-screen overflow-auto z-[1999] px-6"
    >
        <div class="flex items-center justify-between py-6">
            <div class="flex-none">
                <button class="block" @click="$emit('goBack')">
                    <img src="@/assets/images/icons/close.svg" alt="" />
                </button>
            </div>

            <div class="flex-initial">
                <span class="text-lg">Акция</span>
            </div>

            <div class="flex-initial w-[37px]"></div>
        </div>

        <img src="@/assets/images/club/AcquaABS.png" alt="" />

        <p class="text-2xl font-medium mt-8">Поменяй фитнес клуб</p>
        <p class="text-dark mb-8">Акция</p>

        <p>
            Тренировки по технике Acqua-ABS (Abdominal, Back, Stretch)
            представляют собой силовой тренинг, который направлен на тренировку
            мышц спины и брюшного пресса, заканчиваясь каждый раз растяжкой и
            полным расслаблением. Для проведения занятий используется
            специальный спортивный инвентарь, такой как например всевозможные
            резиновые амортизаторы, гантели и т. д. Упражнения в такой технике
            подходят как мужчинам, так и женщинам с различным уровнем физической
            подготовки.
        </p>
    </div>
</template>

<script setup></script>
