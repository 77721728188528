<template>
    <div
        class="fixed top-0 left-0 bg-background w-full overflow-scroll h-screen px-6 z-[1999]"
    >
        <div class="flex items-center justify-between py-6">
            <div class="flex-none">
                <button class="block" @click="$emit('goBack')">
                    <img src="@/assets/images/icons/back.svg" alt="" />
                </button>
            </div>

            <div class="flex-initial">
                <span class="text-lg">{{ route.meta.title }}</span>
            </div>

            <div class="flex-initial w-[37px]"></div>
        </div>

        <div>
            <div class="relative">
                <img src="@/assets/images/club/AcquaABS.png" alt="" />

                <div
                    class="absolute top-4 right-4 text-sm bg-white py-1 px-4 rounded-lg"
                >
                    {{ lessonLong(date, begin_time, end_time) }} минут
                </div>
            </div>

            <div class="flex py-8">
                <div class="flex-1">
                    <p class="text-2xl font-medium">
                        {{ replaceName(lessons[lesson_ID].Name) }}
                    </p>
                    <p class="text-sm text-dark">{{ rooms[room_ID] }}</p>
                </div>

                <div class="flex-initial">
                    <img
                        src="@/assets/images/icons/hearth_favorite.svg"
                        alt=""
                    />
                </div>
            </div>

            <p>
                {{ lessons[lesson_ID].Description }}
            </p>
        </div>

        <div class="w-full h-px bg-gray my-6"></div>

        <div v-if="Object.keys(team).length > 0" class="px-6 mb-40">
            <div class="flex items-center mt-3">
                <div class="flex-initial">
                    <img
                        width="62px"
                        src="@/assets/images/trainer/trainer.png"
                        alt=""
                        class="rounded-full object-cover object-top h-[62px] mr-4"
                    />
                </div>

                <div class="flex-initial">
                    <p class="text-sm text-dark">Инструктор</p>
                    <p class="text-lg font-medium">
                        {{ team[master_ID].LastName }}
                        {{ team[master_ID].FirstName }}
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div
        class="fixed bottom-0 left-0 w-full bg-white rounded-t-2xl p-6 z-[2999]"
    >
        <div class="flex justify-between">
            <div class="flex-initial">
                <p class="text-sm text-dark">
                    {{ format(new Date(date), "dd.MM.Y") }}
                </p>
            </div>

            <div class="flex-initial">
                <p class="text-sm text-dark">
                    {{ schedule[date][time][ID].BeginTime }} -
                    {{ schedule[date][time][ID].EndTime }}
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { replaceName, lessonLong } from "@/functions/function";
import { format } from "date-fns";

const store = useStore();
const route = useRoute();

const schedule = computed(() => {
    return store?.getters?.schedule;
});
const team = computed(() => {
    return store?.getters?.team;
});
const lessons = computed(() => {
    return store?.getters?.lessons;
});
const rooms = computed(() => {
    return store?.getters?.rooms;
});

const ID = route.params.id,
    date = route.query.date,
    time = route.query.time,
    lesson_ID = schedule.value[date][time][ID].LessonTypeID,
    master_ID = schedule.value[date][time][ID].MasterID,
    room_ID = schedule.value[date][time][ID].RoomID,
    begin_time = schedule.value[date][time][ID].BeginTime,
    end_time = schedule.value[date][time][ID].EndTime;
</script>
