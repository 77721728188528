<template>
    <router-view @go-back="$emit('goBack')" />

    <div class="px-6">
        <router-link
            to="/promotion/1"
            class="block bg-purple text-white rounded-2xl p-4 mt-7 mb-3"
        >
            <div class="flex mb-3">
                <div class="flex-1">
                    <p class="text-xs">Акция</p>
                </div>

                <div class="flex-initial">
                    <img src="@/assets/images/icons/arrow_round.svg" alt="" />
                </div>
            </div>

            <p class="text-2xl font-medium mb-4">Фитнес клуб</p>
            <p class="text-sm">
                Предъявите действующую карту вашего клуба, и получите
                дополнительный месяц занятий в подарок
            </p>
        </router-link>

        <router-link
            to="/promotion/2"
            class="block bg-yellow text-white rounded-2xl p-4 mb-3"
        >
            <div class="flex mb-3">
                <div class="flex-1">
                    <p class="text-xs">Акция</p>
                </div>

                <div class="flex-initial">
                    <img src="@/assets/images/icons/arrow_round.svg" alt="" />
                </div>
            </div>

            <p class="text-2xl font-medium mb-4">Знакомство с La Salute</p>
            <p class="text-sm">
                Стоимость гостевого визита + персональная тренировка — 4 000 ₽
            </p>
        </router-link>

        <router-link
            to="/promotion/3"
            class="block bg-pink text-white rounded-2xl p-4 mb-3"
        >
            <div class="flex mb-3">
                <div class="flex-1">
                    <p class="text-xs">Акция</p>
                </div>

                <div class="flex-initial">
                    <img src="@/assets/images/icons/arrow_round.svg" alt="" />
                </div>
            </div>

            <p class="text-2xl font-medium mb-4">Приводи друзей</p>
            <p class="text-sm">
                За каждого приведенного друга получите дополнительный месяц
                занятий в подарок
            </p>
        </router-link>
    </div>
</template>

<script setup></script>
