<template>
    <form
        @submit.prevent="setTokenLogin"
        class="flex flex-col items-center justify-between h-screen pb-16 px-6"
    >
        <div class="flex-initial">
            <img src="@/assets/images/auth/logo-short.png" alt="" />

            <p
                class="text-white font-Bodoni72osc text-46 leading-54 uppercase pt-6"
            >
                Введите логин и пароль
            </p>

            <input
                class="w-full bg-white rounded-2xl text-22 py-3 pl-8 my-6"
                type="text"
                v-model="$validate.login.$model"
                name="login"
                placeholder="Логин"
            />

            <input
                class="w-full bg-white rounded-2xl text-22 py-3 pl-8"
                type="password"
                v-model="$validate.password.$model"
                name="password"
                placeholder="Пароль"
            />
        </div>

        <div class="flex-initial">
            <button
                class="w-full bg-white rounded-2xl text-22 text-center py-3 mb-3"
                type="submit"
                :disabled="$validate.$invalid"
            >
                Войти
            </button>

            <p class="text-white text-xl text-center">
                Нажимая кнопку я соглашась на правила чего-то там
            </p>
        </div>
    </form>
</template>

<script>
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useStore } from "vuex";
//import { useRouter } from "vue-router";

export default {
    setup() {
        let store = useStore(),
            //router = useRouter(),
            login = ref(""),
            password = ref("");

        const setTokenLogin = () => {
            store.dispatch("setTokenLogin", {
                auth_login: login.value,
                auth_password: password.value,
            });
            // router.push("/");
        };

        return {
            $validate: useVuelidate(),
            login,
            password,
            setTokenLogin,
        };
    },
    validations() {
        return {
            login: {
                required,
            },
            password: {
                required,
            },
        };
    },
};
</script>
