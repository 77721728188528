import Axios from "axios";
import { add, format } from "date-fns";

const initialState = () => ({
    schedule: {},
});

const state = initialState();

const getters = {
    schedule: (state) => {
        return state.schedule;
    },
};

const mutations = {
    SET_SCHEDULE: (state, payload) => {
        state.schedule = payload;
    },
};

const actions = {
    setScheduleData: async ({ commit, getters }) => {
        if (Object.keys(getters.schedule).length > 0) return;

        let date = new Date(),
            date_begin = format(new Date(date), "Y-MM-dd");

        date = add(new Date(date), { days: 6 });

        let date_end = format(new Date(date), "Y-MM-dd"),
            schedule = {},
            response = await Axios.get(
                `https://api.fitpass.ru/api/lesson/timetable?club=308&dateBegin=${date_begin}&dateEnd=${date_end}`
            );

        for (let obj of response.data.Data) {
            if (typeof schedule[obj.Date] !== "object") {
                schedule[obj.Date] = {};
            }

            if (typeof schedule[obj.Date][obj.BeginTime] !== "object") {
                schedule[obj.Date][obj.BeginTime] = {};
            }

            if (obj.RoomID == "11") obj.class = "water";
            else obj.class = "group";

            schedule[obj.Date][obj.BeginTime][obj.ID] = obj;
        }

        console.log(schedule);

        commit("SET_SCHEDULE", schedule);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
