import { createRouter, createWebHistory } from "vue-router";
// MAIN
import MainView from "./components/main/MainView.vue";
import PageNotFound from "./components/main/PageNotFound.vue";
import FeedBack from "./components/main/FeedBack.vue";
// AUTH
import HelloPage from "./components/auth/HelloPage.vue";
import AuthLogin from "./components/auth/AuthLogin.vue";
import AuthLoginAdmin from "./components/auth/AuthLoginAdmin.vue";
import AuthCheck from "./components/auth/AuthCheck.vue";
import RegPassword from "./components/auth/RegPassword.vue";
import RegUser from "./components/auth/RegUser.vue";
// USER
import UserProfile from "./components/user/UserProfile.vue";
import UserEditProfile from "./components/user/UserEditProfile.vue";
import UserClubAgreement from "./components/user/UserClubAgreement.vue";
import UserClubFreeze from "./components/user/UserClubFreeze.vue";
import UserClassRegistration from "./components/user/UserClassRegistration.vue";
import UserService from "./components/user/UserService.vue";
import UserFavorite from "./components/user/UserFavorite.vue";
// CLUB
import ClubSchedule from "./components/club/ClubSchedule.vue";
import ClubLessons from "./components/club/ClubLessons.vue";
import ClubTeam from "./components/club/ClubTeam.vue";
import ClubTeamMember from "./components/club/ClubTeamMember.vue";
import ClubPromotion from "./components/club/ClubPromotion.vue";
import ClubPromotionItem from "./components/club/ClubPromotionItem.vue";
import ClubNews from "./components/club/ClubNews.vue";
import ClubNewsItem from "./components/club/ClubNewsItem.vue";

const routes = [
    {
        path: "/hello",
        component: HelloPage,
        meta: {
            img: "/auth/hello_back.png",
            headerHide: true,
            footerHide: true,
        },
    },
    {
        path: "/login",
        component: AuthLogin,
        meta: {
            img: "/auth/hello_back.png",
            headerHide: true,
            footerHide: true,
        },
    },
    {
        path: "/admin",
        component: AuthLoginAdmin,
        meta: {
            img: "/auth/hello_back.png",
            headerHide: true,
            footerHide: true,
        },
    },
    {
        path: "/check",
        component: AuthCheck,
        meta: {
            img: "/auth/hello_back.png",
            headerHide: true,
            footerHide: true,
        },
    },
    {
        path: "/changepassword",
        component: RegPassword,
        meta: {
            img: "/auth/hello_back.png",
            headerHide: true,
            footerHide: true,
        },
    },
    {
        path: "/registration",
        component: RegUser,
        meta: {
            img: "/auth/hello_back.png",
            headerHide: true,
            footerHide: true,
        },
    },

    {
        path: "/",
        components: {
            default: MainView,
        },
        meta: { title: "Главная", menu: true, notification: true },
    },
    {
        path: "/agreement",
        components: {
            default: UserClubAgreement,
        },
        meta: { title: "Клубный договор", menu: false, notification: true },
    },
    {
        path: "/freeze",
        components: {
            default: UserClubFreeze,
        },
        meta: { title: "Заморозка", menu: false, notification: true },
    },
    {
        path: "/user",
        components: {
            default: UserProfile,
        },
        meta: { title: "Профиль", menu: true, notification: true },
        children: [
            {
                path: "profile",
                components: {
                    default: UserEditProfile,
                },
                meta: { title: "Профиль" },
            },
        ],
    },
    {
        path: "/regclass",
        components: {
            default: UserClassRegistration,
        },
        meta: { title: "Мои записи", menu: false, notification: true },
    },
    {
        path: "/services",
        components: {
            default: UserService,
        },
        meta: {
            title: "Мои абонементы и услуги",
            menu: false,
            notification: true,
        },
    },
    {
        path: "/favorite",
        components: {
            default: UserFavorite,
        },
        meta: { title: "Избранное", menu: false, notification: true },
    },
    {
        path: "/schedule",
        components: {
            default: ClubSchedule,
        },
        meta: { title: "Расписание", menu: true, notification: true },
    },
    {
        path: "/lessons/:id",
        components: {
            default: ClubLessons,
        },
        meta: { title: "Занятие", menu: false, notification: false },
    },
    {
        path: "/team",
        components: {
            default: ClubTeam,
        },
        meta: { title: "Тренеры", menu: true, notification: true },
        children: [
            {
                path: ":id",
                components: {
                    default: ClubTeamMember,
                },
                meta: { title: "Тренеры" },
            },
        ],
    },

    {
        path: "/promotion",
        components: {
            default: ClubPromotion,
        },
        meta: { title: "Акции", menu: true, notification: true },
        children: [
            {
                path: ":id",
                components: {
                    default: ClubPromotionItem,
                },
                meta: { title: "Акция" },
            },
        ],
    },

    {
        path: "/news",
        components: {
            default: ClubNews,
        },
        meta: { title: "Новости", menu: true, notification: true },
        children: [
            {
                path: ":id",
                components: {
                    default: ClubNewsItem,
                },
                meta: { title: "Новость" },
            },
        ],
    },
    {
        path: "/feedback",
        components: {
            default: FeedBack,
        },
        meta: { title: "Обратная связь", menu: false, notification: false },
    },
    {
        // path: "*",
        path: "/:catchAll(.*)",
        components: {
            default: PageNotFound,
        },
        meta: {
            title: "NotFound",
            requiresAuth: false,
        },
    },
];

export const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});
